/**
 * @prettier
 */

export function addressLookupReducer(state = {}, action) {
    switch (action.type) {
        case 'ADDRESS_LOOKUP_ACTIVE':
            return {
                ...state,
                active: action.data,
            };

        case 'HIDE_ADDRESS_LOOKUP':
            return {
                ...state,
                hidden: true,
            };

        case 'RESET_ADDRESS_LOOKUP':
            return {
                ...state,
                hidden: false,
                fullResult: '',
                active: false,
            };

        case 'FULL_RESULT_RECEIVED':
            return {
                ...state,
                fullResult: action.data,
            };

        default:
            return state;
    }
}
