/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

// Components
import CustomButton from '../Button';

// Config
import { ACTION_LABELS } from '../../config/dataDogActionLabels';
const ACTION = ACTION_LABELS.messages;

class OutOfRecaptures extends Component {
    constructor(props) {
        super(props);
        this.primaryFocusRef = React.createRef();
    }

    componentDidMount() {
        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }
    }

    render() {
        const { t } = this.props;

        return (
            <Fragment>
                <div className='o-site-wrap'>
                    <div className='o-entryBtns'>
                        <div className='u-text-center u-md-btm-buffer'>
                            <h1 className='large-text u-btm-buffer'>
                                {t(this.props.message)}
                            </h1>

                            <p>{t('idpal_please_continue')}</p>
                        </div>

                        <div id='continue' className='u-text-center'>
                            <CustomButton
                                className={'btn'}
                                label={t('idpal_continue')}
                                handleClick={() => this.props.continue()}
                                actionDataLabel={ACTION.outOfRecaptureButton}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withTranslation('translation')(connect()(OutOfRecaptures));
