/**
 * @prettier
 */

/**
 * Defining allowed document type keys
 *
 * @type object
 */
const allowedDocumentsTypes = {
    passport: 'Passport',
    national_id: 'Identification Card',
    driving_license: 'Drivers License',
    laminated_paper_license: 'Paper Licence',
};

const titleMap = {
    national_id: 'idpal_national_id',
    laminated_paper_license: 'idpal_paper_licence',
    passport: 'idpal_passport',
    driving_license: 'idpal_driving_licence',
};

/**
 * Defining various document properties
 *
 * @type object
 */
let documents = {
    drivingLicence: {
        name: 'Drivers License',
        id: 31,
        dpi: {
            min: 300,
            max: 800,
        },
        dimensions: {
            millimeters: {
                width: '85.60',
                height: '53.98',
            },
            inches: {
                width: '3.3755',
                height: '2.125',
            },
        },
        pages: {
            single: false,
            number: 2,
        },
    },
    residentCard: {
        name: 'Resident Card',
        id: 32,
        dpi: {
            min: 300,
            max: 800,
        },
        dimensions: {
            millimeters: {
                width: '85.60',
                height: '53.98',
            },
            inches: {
                width: '3.3755',
                height: '2.125',
            },
        },
        pages: {
            single: false,
            number: 2,
        },
    },
    nationalId: {
        name: 'France (FRA) Identification Card',
        id: 3,
        dpi: {
            min: 300,
            max: 850,
        },
        dimensions: {
            millimeters: {
                width: '105',
                height: '74',
            },
            inches: {
                width: '4.134',
                height: '2.913',
            },
        },
        pages: {
            single: false,
            number: 2,
        },
    },
    passport: {
        name: 'Passport',
        id: 1,
        dpi: {
            min: 300,
            max: 800,
        },
        dimensions: {
            millimeters: {
                width: '125',
                height: '88',
            },
            inches: {
                width: '4.921',
                height: '3.465',
            },
        },
        pages: {
            single: true,
            number: 1,
        },
    },
    medicalCard: {
        name: 'Medical Card',
        id: 2,
        dpi: {
            min: 300,
            max: 800,
        },
        dimensions: {
            millimeters: {
                width: '',
                height: '',
            },
            inches: {
                width: '',
                height: '',
            },
        },
        pages: {
            single: null,
            number: null,
        },
    },
    identificationCard: {
        name: 'Identification Card',
        id: 4,
        dpi: {
            min: 300,
            max: 800,
        },
        dimensions: {
            millimeters: {
                width: '85.60',
                height: '53.98',
            },
            inches: {
                width: '3.3755',
                height: '2.125',
            },
        },
        pages: {
            single: false,
            number: 2,
        },
    },
    paperLicence: {
        noValidation: true,
        name: 'Paper Licence',
        id: 0,
        dpi: {
            min: 0,
            max: 800,
        },
        dimensions: {
            millimeters: {
                width: '85.60',
                height: '53.98',
            },
            inches: {
                width: '3.3755',
                height: '2.125',
            },
        },
        pages: {
            single: true,
            number: 1,
        },
    },
};

export { documents, allowedDocumentsTypes, titleMap };
