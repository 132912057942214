/**
 * @prettier
 */

export function setCaptured(payload) {
    return {
        type: '@@acuant/ADD_CAPTURED_IMAGE',
        data: payload,
    };
}
