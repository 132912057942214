/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import i18n from './../i18n';
import ReCAPTCHA from 'react-google-recaptcha';

// Actions
import { getUUID } from './actions/configActions';

// Helpers
import { getCompanyName, getLanguage } from '../services/shared/helpers';

// Components
import CustomButton from './Button';
import CustomSelectInput from './Inputs/CustomSelectInput';

// Images
import logo from '../assets/img/idpal_logo.svg';

// Config
import LANGUAGES from '../config/languages';
import { ACTION_LABELS } from '../config/dataDogActionLabels';
const ACTION = ACTION_LABELS.reCaptcha;

class ReCaptchaScreen extends Component {
    constructor(props) {
        super(props);
        this.recaptchaRef = React.createRef();
        this.primaryFocusRef = React.createRef();
        this.state = {
            defaultSelectText: 'Uk-English',
            isSubmitting: false,
            error: {
                isRecaptchaEmpty: false,
            },
        };
        this.handleReCaptchaSubmit = this.handleReCaptchaSubmit.bind(this);
        this.setRecaptchaError = this.setRecaptchaError.bind(this);
    }

    componentDidMount() {
        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }

        // Sets the document title on first render
        const { t } = this.props;
        document.title = t('idpal_doc_title_start');
    }

    componentDidUpdate(preProps, preState, snapshot) {
        // Sets the document title when language is changed on rerenders
        const { t } = this.props;
        document.title = t('idpal_doc_title_start');
    }

    setRecaptchaError() {
        this.recaptchaRef.current.reset();
        this.setState({
            error: {
                isRecaptchaEmpty: true,
            },
        });
    }

    handleReCaptchaSubmit(event) {
        event.preventDefault();
        const recaptchaToken = this.recaptchaRef.current.getValue();
        if (recaptchaToken.length) {
            this.setState({
                isSubmitting: true,
            });
            this.props.getUUID(recaptchaToken);
        } else {
            this.setRecaptchaError();
            this.setState({
                isSubmitting: false,
            });
        }
    }

    render() {
        const { t } = this.props;

        return (
            <Fragment>
                <div className={'start-screen'}>
                    <div className='o-entryBtns'>
                        <h1
                            className='large-text u-text-center u-md-btm-buffer u-sm-ht-btn-buffer u-xsm-ht-btn-buffer'
                            ref={this.primaryFocusRef}
                            tabIndex={0}
                        >
                            {t('idpal_start_screen_blurb', {
                                company: getCompanyName(
                                    this.props.branding.name
                                ),
                            })}
                        </h1>
                    </div>

                    <form onSubmit={this.handleReCaptchaSubmit}>
                        <div className='g-recaptcha-wrapper'>
                            <div className='g-recaptcha-content'>
                                <div
                                    key={this.props.i18n.language}
                                    className={`${
                                        this.state.error.isRecaptchaEmpty
                                            ? 'g-recaptcha-error-border'
                                            : ''
                                    }`}
                                >
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_SITE_KEY}
                                        ref={this.recaptchaRef}
                                        hl={this.props.i18n.language}
                                    />
                                </div>
                                {this.state.error.isRecaptchaEmpty && (
                                    <p className='g-recaptcha-error'>
                                        {t('idpal_recaptcha_prompt')}
                                    </p>
                                )}
                            </div>
                        </div>
                        <CustomButton
                            className='btn'
                            label={t('idpal_start')}
                            isDisabled={this.state.isSubmitting}
                            handleClick={this.handleReCaptchaSubmit}
                            actionDataLabel={ACTION.continueButton}
                        />
                    </form>

                    <div className={'small-text light-text policies'}>
                        {t('idpal_by_clicking_you_agree', {
                            sessionState: t('idpal_start').toLowerCase(),
                        })}
                        <a
                            href={this.props.policies.terms_and_conditions}
                            target={'_blank'}
                            rel='noreferrer'
                            data-dd-action-name={ACTION.policyLink}
                        >
                            {t('idpal_terms_and_conditions')}
                        </a>
                        .{t('idpal_read_our_data_protection')}
                        <a
                            href={this.props.policies.privacy_policy}
                            target={'_blank'}
                            rel='noreferrer'
                            data-dd-action-name={ACTION.privacyLink}
                            aria-label={t(
                                'idpal_read_our_data_protection_label'
                            )}
                        >
                            {t('idpal_here')}
                        </a>
                        .
                    </div>

                    {!this.props.branding.whitelist && (
                        <div className={'logo-section'}>
                            {t('idpal_powered_by')}
                            <img
                                className='small-logo'
                                src={logo}
                                alt={'ID-Pal'}
                            />
                        </div>
                    )}

                    <div className='u-generic-text  u-text-center languageSelection'>
                        <label>{t('idpal_language')}:</label>
                        <CustomSelectInput
                            defaultText={getLanguage(i18n.language)}
                            optionsList={LANGUAGES}
                            isLanguageChange={true}
                            actionDataLabel={ACTION.languageSelect}
                            labelHeader={t('idpal_label_select_language')}
                            elementIdToFocus={i18n.language}
                        />
                    </div>
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        branding: state.config.profile.data.company_branding,
        policies: state.config.profile.data.policies,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getUUID }, dispatch);
}

export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(ReCaptchaScreen)
);
