/**
 * @prettier
 */

/**
 * Defining data dog action labels for interactive elements
 *
 * @type object
 */

const ACTION_LABELS = {
    app: {
        name: 'Page body',
    },
    start: {
        continueButton: 'Continue from start',
        languageSelect: 'Language start',
        policyLink: 'Policy start',
        privacyLink: 'Privacy start',
    },
    reCaptcha: {
        continueButton: 'Continue from ruuid start',
        languageSelect: 'Language ruuid',
        policyLink: 'Policy ruuid',
        privacyLink: 'Privacy ruuid',
    },
    documentSelection: {
        languageSelect: 'Language document selection',
        passportButton: 'Passport',
        drivingLicenceButton: 'Licence',
    },
    eVerification: {
        firstNameInput: 'First name',
        lastNameInput: 'Last name',
        dobDayInput: 'Day of DOB',
        dobMonthInput: 'Month of DOB',
        dobYearInput: 'Year of DOB',
        phoneInput: 'Mobile number',
        countrySelect: 'Country',
        addressOneInput: 'Address line one',
        addressTwoInput: 'Address line two',
        cityInput: 'City field',
        postCodeInput: 'Postcode field',
        customField: 'EVerification custom field',
        submitFormButton: 'Submit e-verifiaction form',
        addressLookup: {
            searchAddressInput: 'Address lookup',
            resultsButton: 'Address lookup result',
            closeButton: 'Enter address manually',
        },
    },
    handoff: {
        sendLinkButton: 'Submit send link',
        resendLinkButton: 'Resend link',
        phoneInput: 'Mobile number',
    },
    instructions: {
        openCameraButton: 'Open camera',
        paperLicenceButton: 'Choose paper licence',
    },
    capturePhoto: {
        continueButton: 'Capture photo continue',
        openCameraPassportButton: 'Passport open camera',
    },
    acuantCamera: {
        backHiddenButton: 'Hidden back button',
        retryButton: 'Retry document capture',
        captureButton: 'Start document capture',
    },
    processResults: {
        continueButton: 'Proceed to next step from results',
        rotateImageButton: 'Rotate image results',
        retryUploadButton: 'Retry document upload',
    },
    captureSelfie: {
        videoStreamButton: 'Start video stream',
        openFrontCameraButton: 'Open front camera',
        captureLivenessButton: 'Open ID-pal liveness camera',
    },
    success: {
        // No interactive elements
        nameInput: '',
    },
    errors: {
        errorRetryButton: 'Error retry',
    },
    messages: {
        outOfRecaptureButton: 'Out of recaptures continue',
        stageCompleteButton: 'Stage complete continue',
    },
};

export { ACTION_LABELS };
