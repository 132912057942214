/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Navigator from '../Navigator';
import { finalizeThenSuccess } from '../actions/navigatorActions';

// Images
import loadingGif from '../../assets/gifs/loading.gif';
import { imageAlt } from '../../config/accessabilityRules';

class DefaultError extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processing: false,
            navigation: {
                action: 'error',
                props: {
                    retryAction: null,
                },
            },
        };
    }

    componentDidMount() {
        // Determine where the error occured if outside of component
        if (
            this.props.location.state &&
            this.props.location.state.type === 'finalize'
        ) {
            this.setState({
                navigation: {
                    action: 'error',
                    props: {
                        retryAction: () => {
                            this.setState({ processing: true });
                            finalizeThenSuccess();
                        },
                    },
                },
            });
        }
    }

    render() {
        return (
            <Fragment>
                <div className='o-site-wrap'>
                    {this.state.processing === true && (
                        <div className='u-display-loading u-text-center'>
                            <img
                                alt={imageAlt.loading}
                                src={loadingGif}
                                className='capture'
                            />
                        </div>
                    )}

                    {this.state.processing === false && (
                        <Navigator
                            page={'default_error'}
                            action={this.state.navigation.action}
                            propsToPass={this.state.navigation.props}
                        />
                    )}
                </div>
            </Fragment>
        );
    }
}

export default connect()(DefaultError);
