/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Components
import Header from '../Header';
import DefaultError from './DefaultError';

class Error extends Component {
    render() {
        return (
            <Fragment>
                <Header />
                <Route
                    path={`${this.props.match.url}/default`}
                    component={DefaultError}
                />
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(Error)
);
