/**
 * @prettier
 */

// React Packages
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

// Actions
import { setErrors } from '../actions/eVerificationActions';
import addressLookupValidatorService from '../../services/shared/addressLookupValidatorService.js';
import {
    clearEVerificationAddress,
    hideAddressLookup,
    setEVerificationAddress,
    setFullResult,
} from '../actions/addressLookupActions';

// Services
import ApiService from '../../services/api/api';

// Components
import Label from './Label';
import TextInput from './TextInput';

// Images
import loadingGif from '../../assets/gifs/loading.gif';

// Config
import { imageAlt, liveFormElements } from '../../config/accessabilityRules';
import { ACTION_LABELS } from '../../config/dataDogActionLabels';

const ACTION = ACTION_LABELS.eVerification.addressLookup;
const errorIds = liveFormElements.addressLookupIds;

class AddressLookup extends Component {
    constructor(props) {
        super(props);
        this.resultsRef = React.createRef();
        this.state = {
            timer: null,
            showResults: false,
            showSpinner: true,
            loadingMessage: '',
            results: [],
            noResults: false,
            resultString: '',
        };
        this.inputUpdated = this.inputUpdated.bind(this);
        this.lookupAddress = this.lookupAddress.bind(this);
        this.propmtKeepTyping = this.propmtKeepTyping.bind(this);
        this.showValidationMessage = this.showValidationMessage.bind(this);
        this.close = this.close.bind(this);
        this.clearFullResult = this.clearFullResult.bind(this);
    }

    componentDidMount() {
        this.setState({
            color: this.props.branding.data.company_branding.primary_color
                ? this.props.branding.data.company_branding.primary_color
                : this.state.color,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.fullResult) {
            this.resultsRef.current.focus();
        }
    }

    inputUpdated(event) {
        this.props.clearEVerificationAddress();
        clearTimeout(this.state.timer);
        this.setState({
            loadingMessage: '',
            showResults: true,
            showSpinner: true,
            results: [],
            noResults: false,
            resultString: event.target.value,
        });

        if (event.target.value.length < 5) {
            this.setState({
                timer: setTimeout(this.propmtKeepTyping, 5000),
            });
        } else {
            this.setState({
                timer: setTimeout(this.lookupAddress, 1000, event.target.value),
            });
        }
    }

    propmtKeepTyping() {
        this.setState({ loadingMessage: 'idpal_keep_typing' });
    }

    showValidationMessage() {
        this.setState({
            loadingMessage: 'idpal_allowed_characters_letters_error',
        });
    }

    lookupAddress(address) {
        ApiService.lookupAddress(
            address,
            this.props.selectedCountry.iso_3166_2
        ).then(response => {
            if (response.code === 400) {
                this.showValidationMessage();
            } else if (response.data) {
                if (response.data.addresses) {
                    this.setState({
                        results: response.data.addresses,
                        showSpinner: false,
                    });
                    if (response.data.addresses.length === 0) {
                        this.setState({
                            noResults: true,
                        });
                    }
                }
            } else {
                this.setState({
                    noResults: true,
                });
            }
        });
    }

    getPlaceholder(countryName) {
        switch (countryName) {
            case 'Ireland':
                return 'idpal_address_lookup_placeholder_ireland';
            case 'United Kingdom':
                return 'idpal_address_lookup_placeholder_uk';
            default:
                return 'idpal_address_lookup_placeholder_default';
        }
    }

    selectAddress(address) {
        let errors = addressLookupValidatorService(address);
        this.props.setEVerificationAddress(address);

        // No errors, keep address in input
        if (Object.keys(errors).length === 0) {
            this.props.setFullResult(address.address);
            this.setState({
                resultString: address.address,
                showResults: false,
            });

            // Missing field, show full form
        } else {
            this.close();
            this.props.setErrors(errors);
        }
    }

    close() {
        clearTimeout(this.state.timer);
        this.props.hideAddressLookup();
    }

    clearFullResult() {
        this.props.setFullResult('');
        this.props.clearEVerificationAddress();
        this.setState({
            showResults: true,
        });
    }

    render() {
        const { t } = this.props;

        const textColor = this.state.color
            ? {
                  color: '#' + this.state.color,
              }
            : {};

        const config = {
            id: 'address_lookup',
            name: 'idpal_address',
            required: 'true',
        };

        return (
            <div className='address-lookup'>
                {!this.props.fullResult && (
                    <TextInput
                        config={config}
                        error={this.props.error}
                        value={this.state.resultString}
                        autocomplete='new-password' // Don't like this, but chrome ignores other ways of preventing autofill
                        placeholder={this.getPlaceholder(
                            this.props.selectedCountry.name
                        )}
                        onChange={this.inputUpdated}
                        aria-describedby={`${errorIds.keepTypingId} ${errorIds.noAddressId}`}
                    />
                )}
                {this.props.fullResult && (
                    <div className='input-group'>
                        <Label
                            id={config.id}
                            required={config.required}
                            label={config.name}
                        />

                        <button
                            className='full-result'
                            onClick={this.clearFullResult}
                            ref={this.resultsRef}
                            data-dd-action-name={ACTION.searchAddressInput} // include if conflict
                        >
                            {this.state.resultString}
                        </button>
                    </div>
                )}

                {this.state.showResults && (
                    <div
                        className={
                            this.state.showResults
                                ? 'address-results'
                                : 'address-results hide'
                        }
                    >
                        {this.state.showSpinner && (
                            <div className='loading'>
                                <img alt={imageAlt.loading} src={loadingGif} />
                                <span
                                    className={'message'}
                                    aria-live='polite'
                                    id={errorIds.keepTypingId}
                                >
                                    {t(this.state.loadingMessage)}
                                </span>
                            </div>
                        )}

                        {this.state.results && !this.state.noResults && (
                            <ul className='results'>
                                {this.state.results.map(result => (
                                    <li key={result.address} className='result'>
                                        <button
                                            onClick={() =>
                                                this.selectAddress(result)
                                            }
                                            className='normalise'
                                            data-dd-action-name={
                                                ACTION.resultsButton
                                            } // include if conflict
                                        >
                                            {result.address}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        )}

                        {this.state.noResults && (
                            <div className='results'>
                                <div
                                    className='result empty'
                                    aria-live='polite'
                                    id={errorIds.noAddressId}
                                >
                                    {t('idpal_no_results')}
                                </div>
                            </div>
                        )}

                        <button
                            className='close'
                            style={textColor}
                            onClick={this.close}
                            data-dd-action-name={ACTION.closeButton} // include if conflict
                        >
                            {t('idpal_enter_address_manually')}
                        </button>
                    </div>
                )}
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        selectedCountry: state.eVerification.selectedCountry,
        fullResult: state.addressLookup.fullResult,
        branding: state.config.profile,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            hideAddressLookup,
            setEVerificationAddress,
            setFullResult,
            setErrors,
            clearEVerificationAddress,
        },
        dispatch
    );
}
export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(AddressLookup)
);
