/**
 * @prettier
 */

const LOADING_MESSAGE_CONFIG = {
    classify: {
        fade_interval_ms: 2500,
        messages_to_animate: [
            'idpal_classifcation_load_message_one',
            'idpal_classifcation_load_message_two',
        ],
    },
    verify: {
        fade_interval_ms: 3500,
        messages_to_animate: [
            'idpal_verification_load_message_one',
            'idpal_verification_load_message_two',
            'idpal_verification_load_message_three',
        ],
    },
};

export default LOADING_MESSAGE_CONFIG;
