/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

// Components
import CustomButton from '../Button';

// Images
import successSvg from '../../assets/img/success.svg';

// Config
import { ACTION_LABELS } from '../../config/dataDogActionLabels';
const ACTION = ACTION_LABELS.messages;

class StageComplete extends Component {
    constructor(props) {
        super(props);
        this.primaryFocusRef = React.createRef();
    }

    componentDidMount() {
        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }
    }

    render() {
        const { t } = this.props;

        return (
            <Fragment>
                <div className='o-site-wrap'>
                    <div className='o-entryBtns'>
                        <div className='u-text-center u-md-btm-buffer'>
                            <h1
                                className='large-text'
                                ref={this.primaryFocusRef}
                                tabIndex={0}
                            >
                                {t(this.props.message)}
                            </h1>

                            <div className='u-success-logo u-text-center u-btm-buffer'>
                                <img alt='success' src={successSvg} />
                            </div>

                            {!this.props.hideContinue && (
                                <p>{t('idpal_please_continue')}</p>
                            )}
                        </div>

                        {!this.props.hideButton && (
                            <div id='continue' className='u-text-center'>
                                <CustomButton
                                    className={'btn'}
                                    label={t('idpal_continue')}
                                    handleClick={() => this.props.continue()}
                                    actionDataLabel={ACTION.stageCompleteButton}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withTranslation('translation')(connect()(StageComplete));
