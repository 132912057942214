/**
 * @prettier
 */

import { configReducer } from './screens/reducers/configReducer';
import { processedDataReducer } from './screens/reducers/processedDataReducer';
import { idPropertiesReducer } from './screens/reducers/idPropertiesReducer';
import { eVerificationReducer } from './screens/reducers/eVerificationReducer';
import { spinnerReducer } from './screens/reducers/spinnerReducer';
import { submissionStateReducer } from './screens/reducers/submissionStateReducer';
import { addressLookupReducer } from './screens/reducers/addressLookupReducer';

const rootReducer = {
    config: configReducer,
    processedData: processedDataReducer,
    idProperties: idPropertiesReducer,
    eVerification: eVerificationReducer,
    spinner: spinnerReducer,
    submissionState: submissionStateReducer,
    addressLookup: addressLookupReducer,
};

export default rootReducer;
