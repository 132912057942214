/**
 * @prettier
 */

// React Packages
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Components
import Label from './Label.js';
import SimpleDateInput from './SimpleDateInput';

class DateInput extends Component {
    render() {
        return (
            <div
                className={
                    this.props.error ? 'error input-group' : 'input-group'
                }
            >
                <Label
                    id={this.props.config.id}
                    required={this.props.config.required}
                    label={this.props.config.name}
                />
                <SimpleDateInput
                    id={this.props.config.id}
                    format={this.props.format}
                    onChange={this.props.onChange}
                    required={this.props.config.required}
                    error={this.props.error ? true : false}
                    errorMessage={this.props.error}
                    actionDataLabels={this.props.actionDataLabels}
                />

                {this.props.error && (
                    <span className='error-message'>{this.props.error}</span>
                )}
            </div>
        );
    }
}

export default connect()(DateInput);
