/**
 * @prettier
 */

/**
 * Defining form focus rules
 *
 * @type object
 */

const focusInputIds = {
    addressLookup: 'address_lookup',
    addressLine1: 'address1',
    firstName: 'firstName',
};

/**
 * Defining aria-labels
 *
 * @type object
 */

const hiddenFormLabels = {
    day: 'day of birth, format DD required',
    month: 'month of birth, format M M required', // must include a space, screen readers read mm as millimeter
    year: 'year of birth, format YYYY required',
    mobile: 'mobile number required',
    infoTip:
        'We need this to contact you if there are any issues verifying your details.',
    handoff: 'Send a link to your phone',
};

/**
 * Defining aria-labelledby labels and ids
 *
 * @type object
 */

const liveFormElements = {
    addressLookupIds: {
        keepTypingId: 'keepTyping',
        noAddressId: 'noAddress',
    },
};

const imageAlt = {
    passport: 'Passport',
    driving: 'Driving licence',
    drivingBack: 'Back of a driving licence',
    nationalIdBack: 'National ID',
    nationalIdFront: 'Back of a national ID',
    selfieInstructions: 'Person taking a selfie',
    loading: 'Loading anamation',
    analysing: 'Analysing',
    idCardLoading: 'Analysing document',
    upLoadedDocument: 'Captured document',
    proofOfAddressPreview: 'Captured Proof of address document',
    proofOfAddress: 'Example proof of address document',
    success: 'Success',
};

export { focusInputIds, hiddenFormLabels, liveFormElements, imageAlt };
