/**
 * @prettier
 */

import i18next from 'i18next';
import { addressLookup } from '../../config/addressLookup';

export function validate(address) {
    let rules = addressLookup;
    let fields = Object.keys(rules);
    let errors = {};

    // Validate fields against rules
    for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        let rule = rules[field];
        let addressItem = address[field];

        // Check that required field is not empty
        if (
            (addressItem === null || addressItem.trim() === '') &&
            rule['required']
        ) {
            errors[rule['id']] = i18next.t('idpal_field_is_required');
        }
    }
    return errors;
}

export default validate;
