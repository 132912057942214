/**
 * @prettier
 */

import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { unregister } from './registerServiceWorker';
import { store, persistor, history } from './store';
import './i18n';

window.appData = {};

ReactDOM.render(
    <App routerHistory={history} store={store} persistor={persistor} />,
    document.getElementById('root')
);
unregister();
