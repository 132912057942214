/**
 * @prettier
 */

import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

export class InstructionModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isHidden: 'isHidden',
        };

        this.modalContentRef = createRef();
        this.modalRef = createRef();
        this.primaryFocusRef = createRef();

        this.handleToggleModal = this.handleToggleModal.bind(this);
    }

    componentDidMount() {
        this.setState({
            isHidden: '',
        });

        // Sets the primary focus to the modal for accessibility
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            let heading = this.primaryFocusRef.current;
            heading.focus();
        }
    }

    handleToggleModal() {
        this.setState({
            isHidden: 'isHidden',
        });

        // Animates the fade when closing the modal
        if (this.modalRef && this.modalRef.current) {
            this.modalRef.current.className = 'animate-out';
        }

        setTimeout(() => {
            this.modalRef.current.className = 'isHidden';
        }, 500);
    }

    render() {
        const { heading, message, listItems, image, cta, showCta } = this.props;

        return (
            <div ref={this.modalRef}>
                <div className='modal-overlay' />
                <div
                    ref={this.modalContentRef}
                    className='modal-card animate-opacity rotated'
                >
                    <div className='header'>
                        <h1 ref={this.primaryFocusRef} tabIndex={0}>
                            {heading}
                        </h1>
                    </div>
                    <div className='content'>
                        {image && (
                            <div className='image-container'>
                                <img alt={heading} src={image} />
                            </div>
                        )}
                        <div className='message'>
                            <p>{message}</p>
                            {listItems && (
                                <ul>
                                    {listItems.map((item, index) => (
                                        <li key={index} className='items'>
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className='footer'>
                        {showCta ? (
                            <button
                                className='modal-btn'
                                onClick={() => this.handleToggleModal()}
                            >
                                {cta ? cta.toUpperCase() : 'OK'}
                            </button>
                        ) : (
                            <div className='modal-btn'></div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation('translation')(connect()(InstructionModal));
