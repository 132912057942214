/**
 * @prettier
 */

import { areCookiesEnabled } from '../services/shared/helpers';
import selectStorage from '../services/shared/selectStorage';
/**
 * Setting env variables to limit usage of .env file within the codebase.
 *
 * @type object
 */
let environment = {
    dpiMinPassport: process.env.REACT_APP_DPI_MIN_PASSPORT || 300,
    dpiMin: process.env.REACT_APP_DPI_MIN || 600,
    dpiMax: process.env.REACT_APP_DPI_MAX || 800,
    glareThreshold: process.env.REACT_APP_GLARE_THRESHOLD || 50,
    sharpnessThreshold: process.env.REACT_APP_SHARPNESS_THRESHOLD || 50,
    croppedPercentageThreshold:
        process.env.REACT_APP_CROPPED_PERCENTAGE_THRESHOLD || 11,
    debugMode: process.env.REACT_APP_DEBUG_MODE === 'true',
    maxFileSize: process.env.REACT_APP_MAX_FILE_SIZE || 2000000,
    maxWidth: process.env.REACT_APP_MAX_WIDTH || 8858,
    maxHeight: process.env.REACT_APP_MAX_HEIGHT || 6237,
    apiDomain: () => {
        // Retrieve store item if cookies are enabled
        const region = areCookiesEnabled()
            ? selectStorage(areCookiesEnabled()).getItem('region')
            : null;

        // Define US API domain
        if (region !== null && region === 'us') {
            return process.env.REACT_APP_ID_US_ENDPOINT;
        }

        // Default API domain
        return process.env.REACT_APP_ID_ENDPOINT;
    },
};

export { environment };
