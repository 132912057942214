/**
 * @prettier
 */

export function spinnerReducer(state = {}, action) {
    switch (action.type) {
        case 'COMPLETE':
            return {
                ...state,
                pendingItems: state.pendingItems - 1,
            };
        case 'PENDING':
            return {
                ...state,
                pendingItems: state.pendingItems + 1,
            };
        case 'RESET_SPINNER':
            return {
                ...state,
                pendingItems: 0,
            };
        default:
            return state;
    }
}
