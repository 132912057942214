/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import i18n from './../i18n';

// Helpers
import { getCompanyName, getLanguage } from '../services/shared/helpers';

// Components
import Header from './Header';
import Navigator from './Navigator';
import CustomButton from './Button';
import CustomSelectInput from './Inputs/CustomSelectInput';

// Images
import logo from '../assets/img/idpal_logo.svg';

// Config
import LANGUAGES from '../config/languages';
import { ACTION_LABELS } from '../config/dataDogActionLabels';

// Constants
const ACTION = ACTION_LABELS.start;

class ReusableProfileStart extends Component {
    constructor(props) {
        super(props);
        this.primaryFocusRef = React.createRef();
        this.state = {
            navigation: {
                action: 'load',
                props: null,
            },
        };
        this.continue = this.continue.bind(this);
    }

    componentDidMount() {
        // Sets focus to primary heading on first render
        const { t } = this.props;
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }

        // Sets the document title on the first render
        document.title = t('idpal_doc_title_start');
    }

    continue() {
        this.setState({
            navigation: {
                action: 'next',
            },
        });
    }

    render() {
        const { t } = this.props;

        return (
            <Fragment>
                <Header />

                <div className='o-site-wrap'>
                    <div className='start-screen'>
                        <div className='o-entryBtns'>
                            <h1
                                className='large-text u-text-center u-small-text-buffer'
                                ref={this.primaryFocusRef}
                                tabIndex={0}
                            >
                                {t('idpal_reusable_start_title')}
                            </h1>
                            <h2 className='u-text-center u-generic-text u-md-btm-buffer u-sm-ht-btn-buffer u-xsm-ht-btn-buffer'>
                                {t('idpal_reusable_start_subtitle', {
                                    company: getCompanyName(
                                        this.props.branding.name
                                    ),
                                })}
                            </h2>
                        </div>

                        <CustomButton
                            id='continue'
                            className='btn btn__arrow'
                            label={t('idpal_start')}
                            handleClick={this.continue}
                            actionDataLabel={ACTION.continueButton}
                        />

                        <div className={'small-text light-text policies'}>
                            {t('idpal_by_clicking_you_agree', {
                                sessionState: t('idpal_start').toLowerCase(),
                            })}
                            <a
                                href={this.props.policies.terms_and_conditions}
                                target={'_blank'}
                                rel='noreferrer'
                                data-dd-action-name={ACTION.policyLink}
                            >
                                {t('idpal_terms_and_conditions')}
                            </a>
                            .{t('idpal_read_our_data_protection')}
                            <a
                                href={this.props.policies.privacy_policy}
                                target={'_blank'}
                                rel='noreferrer'
                                data-dd-action-name={ACTION.privacyLink}
                                aria-label={t(
                                    'idpal_read_our_data_protection_label'
                                )}
                            >
                                {t('idpal_here')}
                            </a>
                            .
                        </div>

                        {!this.props.branding.whitelabel && (
                            <div className={'logo-section'}>
                                {t('idpal_powered_by')}
                                <img
                                    className='small-logo'
                                    src={logo}
                                    alt={'ID-Pal'}
                                />
                            </div>
                        )}

                        <div className='u-generic-text  u-text-center languageSelection'>
                            <label>{t('idpal_language')}:</label>
                            <CustomSelectInput
                                defaultText={getLanguage(i18n.language)}
                                optionsList={LANGUAGES}
                                isLanguageChange={true}
                                actionDataLabel={ACTION.languageSelect}
                                labelHeader={t('idpal_label_select_language')}
                                elementIdToFocus={i18n.language}
                            />
                        </div>
                    </div>
                </div>

                <Navigator
                    page={'re_verification_start'}
                    action={this.state.navigation.action}
                    propsToPass={this.state.navigation.props}
                />
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        branding: state.config.profile.data.company_branding,
        policies: state.config.profile.data.policies,
    };
}

export default withTranslation('translation')(
    connect(mapStateToProps)(ReusableProfileStart)
);
