/**
 * @prettier
 */

import { jsPDF } from 'jspdf';

/**
 * Creates pdf from images
 * @returns {function} - generatePdfFromImages
 */
export default function pdfCreationService() {
    // A4 width and hight using millimeters for units
    const A4_PAPER_DIMENSIONS = {
        width: 210,
        height: 297,
    };

    const A4_PAPER_RATIO =
        A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;

    /**
     * Sets the image dimensions for A4 page, based on image width and hight
     * @param {object} dimensions - object containing original image width and height properties.
     * @returns {object} - image width and height properties for pdf creation.
     */
    function setImageDimensionsOnA4(dimensions) {
        const isLandscapeImage = dimensions.width >= dimensions.height;

        // If the image is in landscape, the full width of A4 is used.
        if (isLandscapeImage) {
            return {
                width: A4_PAPER_DIMENSIONS.width,
                height:
                    A4_PAPER_DIMENSIONS.width /
                    (dimensions.width / dimensions.height),
            };
        }

        const imageRatio = dimensions.width / dimensions.height;

        // If the image too large in portrait scale the image to retain he image ratio
        if (imageRatio > A4_PAPER_RATIO) {
            const imageScaleFactor =
                (A4_PAPER_RATIO * dimensions.height) / dimensions.width;
            const scaledImageHeight =
                A4_PAPER_DIMENSIONS.height * imageScaleFactor;

            return {
                height: scaledImageHeight,
                width: scaledImageHeight * imageRatio,
            };
        }

        // If the image fits in portrait, retain the current size.
        return {
            width:
                A4_PAPER_DIMENSIONS.height /
                (dimensions.height / dimensions.width),
            height: A4_PAPER_DIMENSIONS.height,
        };
    }

    /**
     * Creates an pdf from an image and returns in specified data type.
     * @param {image} image - uploaded image dataUrl.
     * @param {string} mimeType - original image mimeType, jpg, png etc.
     * @param {string} dataType - pdf datatype to be returned, blob, arraybuffer etc.
     * @param {object} dimensions - optional argument - for captured images width and height properties must be specified.
     * @returns {jsPDF} - pdf in specified datatype.
     */
    function generatePdfFromImages(
        image,
        mimeType,
        dataType,
        dimensions = null
    ) {
        // Default export is A4 paper, portrait, using millimeters for units.
        const doc = new jsPDF();

        let imageDimensions = null;
        let isCapture = false;

        // First check check for image dimensions (for captured images)
        if (dimensions) {
            imageDimensions = setImageDimensionsOnA4({
                width: dimensions.width,
                height: dimensions.height,
            });
            isCapture = true;
        } else {
            imageDimensions = setImageDimensionsOnA4({
                width: image.width,
                height: image.height,
            });
        }

        // Remove the first default page
        doc.deletePage(1);
        doc.addPage();
        doc.addImage(
            isCapture ? image : image.src,
            mimeType,
            // Images are vertically and horizontally centered on the page.
            (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
            (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
            imageDimensions.width,
            imageDimensions.height
        );

        return doc.output(dataType);
    }

    return {
        generatePdfFromImages,
    };
}
