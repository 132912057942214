/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

// Components
import Header from './Header';
import Navigator from './Navigator';
import CustomButton from './Button';
import InstructionModal from './Messages/InstructionModal';
import StageComplete from './Messages/StageComplete';

// Services
import {
    bytesToSize,
    getCompanyName,
    isSubmissionComplete,
} from '../services/shared/helpers';
import ApiService from '../services/api/api';
import pdfCreationService from '../services/shared/pdfCreationService';
import { PDFDocument } from 'pdf-lib';

// Actions
import { clearError } from './actions/navigatorActions';
import { completeStage } from './actions/submissionStateActions';

// Images
import loadingGif from '../assets/gifs/loading.gif';
import poaInstructions from '../assets/img/poa.png';
import poaModalImage from '../assets/img/proof_address_modal.jpg';

// Config
import { imageAlt } from '../config/accessabilityRules';
import DataDogService from '../services/shared/datadogService';
import logService from '../services/shared/logService';
import { ACTION_LABELS } from '../config/dataDogActionLabels';

const ACTION = ACTION_LABELS.errors;

class UploadProofOfAddress extends Component {
    constructor(props) {
        super(props);

        this.primaryFocusRef = React.createRef();
        this.state = {
            poaImage: null,
            mimeType: null,
            dimensions: {},
            maxFileSize: 10, // size in MB.
            allowedUnits: ['Bytes', 'KB'],
            allowedFileTypes: ['image/jpeg', 'image/png', 'application/pdf'],
            pdfFileType: 'blob', // pdf file conversion type
            isInvalidFileType: false,
            isInvalidFileSize: false,
            isCaptureError: false,
            showModal: false,
            isLoading: false,
            isSubmitted: false,
            showPreview: false,
            showStageComplete: false,
            errorRetryCount: 3,
            isRetryLimitReached: false,
            navigation: {
                action: 'load',
                props: null,
            },
        };

        this.handleSubmitCapturedPdf = this.handleSubmitCapturedPdf.bind(this);
        this.handleStartCamera = this.handleStartCamera.bind(this);
        this.handleSelectFiles = this.handleSelectFiles.bind(this);
        this.handleRetryCapture = this.handleRetryCapture.bind(this);
        this.handleCaptureErrorRetry = this.handleCaptureErrorRetry.bind(this);
    }

    componentDidMount() {
        // Show completed screen if poa is already uploaded
        if (this.props.poaCompleted) {
            this.setState({ showStageComplete: true });
        }

        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }

        // Sets document title
        document.title = 'Proof of Address - Upload';
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }

        // Sets modal for invalid file size
        if (this.state.isInvalidFileSize !== prevState.isInvalidFileSize) {
            if (this.state.isInvalidFileSize) {
                this.setState({ showModal: true });
            }
        }

        // Sets modal for invalid file type
        if (this.state.isInvalidFileType !== prevState.isInvalidFileType) {
            if (this.state.isInvalidFileType) {
                this.setState({ showModal: true });
            }
        }

        // Continue once pdf is submitted.
        if (this.state.isSubmitted !== prevState.isSubmitted) {
            if (this.state.isSubmitted) {
                this.props.completeStage('poa_upload');
                this.setState({
                    navigation: {
                        action: 'next',
                    },
                });
            }
        }
    }

    componentWillUnmount() {
        // Remove all event listers
        const capturePOA = document.getElementById('capturePOA');
        const uploadPOA = document.getElementById('uploadPOA');

        if (capturePOA) {
            capturePOA.removeEventListener('change', this.handleCameraCapture);
        }

        if (uploadPOA) {
            uploadPOA.removeEventListener('change', this.handleUploadPoaFile);
        }
    }

    /**
     *
     * Camera POA Capture starts here
     *
     **/

    // Starts camera poa capture
    handleStartCamera() {
        document.getElementById('capturePOA').click();
        document
            .getElementById('capturePOA')
            .addEventListener('change', event =>
                this.handleCameraCapture(event)
            );
    }

    handleCameraCapture(event) {
        const self = this;
        const poaInput = document.getElementById('capturePOA');
        if (poaInput.files && poaInput.files[0]) {
            try {
                // First get the mimeType for creating pdf, will always be jpg as it is drawn to the canvas.
                self.setState({ mimeType: 'jpg' });
                const fileReader = new FileReader();
                fileReader.readAsDataURL(poaInput.files[0]);
                fileReader.addEventListener('load', function (event) {
                    const image = new Image();
                    image.src = event.target.result;
                    image.onload = function () {
                        self.setState({
                            poaImage: self.drawToCanvas(this),
                            showPreview: true,
                            // Set the dimensions for the pdf
                            dimensions: {
                                width: this.width,
                                height: this.height,
                            },
                        });
                    };
                });
                // Reset the input value
                poaInput.value = null;
            } catch (error) {
                poaInput.value = null;
                this.setState({ isCaptureError: true });
                DataDogService.createError(error);
                logService.error(error);
            }
        } else {
            // Reset the input value
            poaInput.value = null;
        }
    }

    // Draw captured image to canvas to retain image orientation.
    drawToCanvas(image) {
        const hiddenCanvas = document.querySelector('#poaCanvas');
        hiddenCanvas.width = image.width;
        hiddenCanvas.height = image.height;

        const ctx = hiddenCanvas.getContext('2d');
        ctx.mozImageSmoothingEnabled = false;
        ctx.webkitImageSmoothingEnabled = false;
        ctx.msImageSmoothingEnabled = false;
        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(image, 0, 0, image.width, image.height);

        const canvasImage = hiddenCanvas.toDataURL('image/jpeg');
        return canvasImage;
    }

    // Submit the image after preview
    handleSubmitCapturedPdf() {
        if (this.state.poaImage && this.state.mimeType) {
            const pdf = this.convertImageToPDF(
                this.state.poaImage,
                this.state.mimeType,
                this.state.dimensions
            );
            this.postPoaFile(pdf, this.props.poaUploadKey);
        } else {
            DataDogService.createError(
                'Error uploading proof of address file, no image captured'
            );
        }
    }

    // Reset for new capture attempt
    resetCaptureState() {
        this.setState({
            poaImage: null,
            showPreview: false,
            isCaptureError: false,
        });
    }

    // Retry capture/upload error
    handleCaptureErrorRetry() {
        document
            .getElementById('capturePOA')
            .removeEventListener('change', this.handleCameraCapture);

        if (this.state.errorRetryCount <= 1) {
            this.setState({ isRetryLimitReached: true });
        } else {
            this.setState(prevState => {
                return {
                    ...prevState,
                    errorRetryCount: prevState.errorRetryCount - 1,
                };
            });
        }

        this.resetCaptureState();
    }

    // Retry Capture POA
    handleRetryCapture() {
        document
            .getElementById('capturePOA')
            .removeEventListener('change', this.handleCameraCapture);
        this.resetCaptureState();
    }

    /**
     *
     * File upload POA starts here
     *
     **/

    // Select file
    handleSelectFiles() {
        this.resetValidation();
        document.getElementById('uploadPOA').click();

        // Set change handler to fire only once to prevent multiple api calls.
        document
            .getElementById('uploadPOA')
            .addEventListener(
                'change',
                event => this.handleUploadPoaFile(event),
                { once: true }
            );
    }

    // Load image and convert to pdf
    uploadImageAsPdf(file) {
        const self = this;
        const mimeType = 'jpg';

        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.addEventListener('load', function (event) {
            const image = new Image();
            image.src = event.target.result;
            image.onload = function () {
                // Convert to pdf
                const dimensions = {
                    width: this.width,
                    height: this.height,
                };
                const pdf = self.convertImageToPDF(
                    self.drawToCanvas(this),
                    mimeType,
                    dimensions
                );
                // Send file
                self.postPoaFile(pdf, self.props.poaUploadKey);
            };
        });
    }

    // Upload file and perform file validation
    handleUploadPoaFile(event) {
        // Set loading at this stage.
        const poaInput = document.getElementById('uploadPOA');
        if (poaInput.files && poaInput.files[0]) {
            try {
                const file = poaInput.files[0];

                if (this.isValidFile(file)) {
                    if (file.type === 'application/pdf') {
                        // Read PDF file
                        const reader = new FileReader();
                        reader.readAsArrayBuffer(file);

                        // When reader is ready
                        reader.onload = async () => {
                            try {
                                // Load pdf into new pdf
                                const pdfDoc = await PDFDocument.load(
                                    reader.result,
                                    { ignoreEncryption: true }
                                );

                                // Save new pdf data
                                const pdfBytes = await pdfDoc.save({
                                    useObjectStreams: false,
                                });

                                // Build pdf file
                                const bytes = await new Uint8Array(pdfBytes);
                                const blob = await new Blob([bytes], {
                                    type: 'application/pdf',
                                });

                                // Send new pdf file
                                this.postPoaFile(blob, this.props.poaUploadKey);
                            } catch (err) {
                                // If building fails, send original file
                                this.postPoaFile(file, this.props.poaUploadKey);
                            }
                        };
                    } else {
                        this.uploadImageAsPdf(file);
                    }
                }

                // Set invalid file size modal
                if (!this.isValidFileSize(file.size)) {
                    this.setState({ isInvalidFileSize: true });
                }

                // Set invalid file type modal
                if (!this.isValidFileType(file.type)) {
                    this.setState({ isInvalidFileType: true });
                }
            } catch (error) {
                poaInput.value = null;
                this.setState({ isCaptureError: true });
                DataDogService.createError(error);
                logService.error(error);
            }
            poaInput.value = null;
        } else {
            poaInput.value = null;
        }
    }

    /**
     *
     * General functionality starts here
     *
     **/

    // Show modal if ID is already submitted
    showDocumentUploadModal() {
        if (this.props.isDocSubmitted && this.props.docCompleted) {
            return true;
        }
        return false;
    }

    // Convert file to pdf
    convertImageToPDF(file, mimeType, dimensions) {
        const { generatePdfFromImages } = pdfCreationService();
        return generatePdfFromImages(
            file,
            mimeType,
            this.state.pdfFileType,
            dimensions
        );
    }

    // Submit the proof of address document to the backend
    postPoaFile(file, poaUploadKey) {
        clearError(this);
        this.setState({ isLoading: true });
        ApiService.uploadPoa(file, poaUploadKey)
            .then(response => {
                // Log success response to datadog
                DataDogService.log(
                    'Successfully send Proof of Address document.'
                );
                // Set valid document uploaded
                response?.data?.validKey &&
                    this.setState({ isSubmitted: true });
            })
            .catch(error => {
                // Handle errors
                DataDogService.createError(
                    'Unable to send Proof of Address document.'
                );
                logService.error(error);
                this.setState({
                    isLoading: false,
                    isSubmitted: false,
                    navigation: {
                        action: 'error',
                        props: {
                            retryAction: () =>
                                this.postPoaFile(file, poaUploadKey),
                        },
                    },
                });
            });
    }

    // Removes backslash from string
    sanitiseString(string) {
        return string.replace(/\\/g, ' ');
    }

    /**
     *
     * File validation starts here
     *
     **/

    // Check Valid file
    isValidFile(file) {
        return (
            this.isValidFileType(file.type) && this.isValidFileSize(file.size)
        );
    }

    // Check valid file type
    isValidFileType(fileType) {
        return this.state.allowedFileTypes.includes(fileType);
    }

    // Check valid file size
    isValidFileSize(fileSize) {
        const fileSizeData = bytesToSize(fileSize);

        if (this.state.allowedUnits.includes(fileSizeData.unit)) {
            return true;
        }

        if (
            fileSizeData.unit === 'MB' &&
            fileSizeData.size <= this.state.maxFileSize
        ) {
            return true;
        }

        return false;
    }

    // Reset validation state
    resetValidation() {
        this.setState({
            isInvalidFileSize: false,
            isInvalidFileType: false,
            showModal: false,
        });
    }

    render() {
        const { t } = this.props;
        const textColor = this.props.color
            ? {
                  color: '#' + this.props.color,
              }
            : {};

        if (isSubmissionComplete()) {
            return (
                <Fragment>
                    <Header />
                    <StageComplete
                        message={t('idpal_your_submission_is_complete', {
                            company: getCompanyName(this.props.companyName),
                        })}
                        hideContinue={true}
                        hideButton={true}
                    />
                </Fragment>
            );
        }

        // Document upload is already completed
        if (
            this.state.showStageComplete &&
            this.state.navigation.action === 'load'
        ) {
            return (
                <>
                    <Header />
                    <StageComplete
                        continue={() =>
                            this.setState({ navigation: { action: 'next' } })
                        }
                        message='idpal_poa_captured'
                    />
                    <Navigator
                        page={'proof_of_address'}
                        action={this.state.navigation.action}
                        propsToPass={this.state.navigation.props}
                    />
                </>
            );
        }

        return (
            <>
                {this.showDocumentUploadModal() && (
                    <InstructionModal
                        heading={t('idpal_document_upload_completed')}
                        message={t(
                            'idpal_document_upload_completed_poa_message'
                        )}
                        image={poaModalImage}
                        cta={t('idpal_continue')}
                        showCta={true}
                    />
                )}

                {this.state.showModal && this.state.isInvalidFileSize && (
                    <InstructionModal
                        heading={t('idpal_file_too_large')}
                        message={t('idpal_file_too_large_message')}
                        showCta={true}
                    />
                )}

                {this.state.showModal && this.state.isInvalidFileType && (
                    <InstructionModal
                        heading={t('idpal_file_wrong_type')}
                        message={t('idpal_file_wrong_type_message')}
                        showCta={true}
                    />
                )}

                <Header />
                {/* Show loading state */}
                {this.state.isLoading && (
                    <div className='o-site-wrap instructions'>
                        <div className='u-generic-text  u-text-center u-btm-buffer'>
                            <h1
                                ref={this.primaryFocusRef}
                                tabIndex={0}
                                className='loading-ellipse'
                            >
                                {t('idpal_analysing')}
                                <span className='dot1'>.</span>
                                <span className='dot2'>.</span>
                                <span className='dot3'>.</span>
                            </h1>
                        </div>

                        <div className='u-display-loading u-text-center'>
                            <img
                                alt={imageAlt.loading}
                                src={loadingGif}
                                className='capture'
                            />
                        </div>
                    </div>
                )}

                {!this.state.isLoading && (
                    <div className='o-site-wrap upload-proof-of-address'>
                        {/* Show instructions state */}
                        {!this.state.showPreview &&
                            !this.state.isCaptureError && (
                                <>
                                    <div className='u-text-center u-btm-buffer'>
                                        <h1
                                            className='u-generic-text'
                                            ref={this.primaryFocusRef}
                                            tabIndex={0}
                                        >
                                            {`${t('idpal_poa_title')} ${this.sanitiseString(this.props.title)}`}
                                        </h1>
                                        <h2 className='u-generic-text subtitle'>
                                            {this.sanitiseString(
                                                this.props.subTitle
                                            )}
                                        </h2>
                                        <div className='img-wrap'>
                                            <div className='svg-wrap'>
                                                <img
                                                    src={poaInstructions}
                                                    alt={
                                                        imageAlt.proofOfAddress
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='button_container'>
                                        <CustomButton
                                            id='openCamera'
                                            type='customHover'
                                            className={'btn outline'}
                                            label={t('idpal_open_camera_caps')}
                                            handleClick={this.handleStartCamera}
                                        />
                                        <CustomButton
                                            id='uploadFile'
                                            className='btn'
                                            label={t('idpal_choose_file_caps')}
                                            handleClick={this.handleSelectFiles}
                                        />
                                    </div>
                                </>
                            )}

                        {this.state.showPreview && (
                            <>
                                {/* Show preview state */}
                                <div className='u-text-center u-btm-buffer'>
                                    <h1
                                        className='u-generic-text u-btm-buffer'
                                        ref={this.primaryFocusRef}
                                        tabIndex={0}
                                    >
                                        {t('idpal_poa_preview_message')}
                                    </h1>
                                    <div className='img-wrap'>
                                        <img
                                            className='preview-image'
                                            src={this.state.poaImage}
                                            alt={imageAlt.proofOfAddress}
                                        />
                                    </div>
                                </div>
                                <div className='button_container'>
                                    <CustomButton
                                        id='Accept'
                                        className='btn'
                                        label={t('idpal_accept')}
                                        handleClick={
                                            this.handleSubmitCapturedPdf
                                        }
                                    />
                                    <div className='u-text-center btn-link font-normalize'>
                                        <button
                                            className='btn-link font-normalize btn-divider'
                                            style={textColor}
                                            onClick={this.handleRetryCapture}
                                        >
                                            {t('idpal_retake_photo')}
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}

                        {this.state.isCaptureError && (
                            <>
                                <h1
                                    className='large-text u-text-center u-btm-buffer'
                                    ref={this.primaryFocusRef}
                                    tabIndex={0}
                                >
                                    {!this.state.isRetryLimitReached
                                        ? t(
                                              'idpal_application_encountered_an_error'
                                          )
                                        : t('idpal_poa_error_message')}
                                </h1>

                                {!this.state.isRetryLimitReached && (
                                    <CustomButton
                                        id={'retry'}
                                        label={t('idpal_try_again')}
                                        className={'btn'}
                                        handleClick={
                                            this.handleCaptureErrorRetry
                                        }
                                        actionDataLabel={
                                            ACTION.errorRetryButton
                                        }
                                    />
                                )}
                            </>
                        )}
                    </div>
                )}
                <input
                    className='hidden'
                    type='file'
                    id='capturePOA'
                    accept='image/*'
                    capture
                    onClick={event => this.handleCameraCapture(event)}
                />
                <input
                    className='hidden'
                    type='file'
                    id='uploadPOA'
                    accept='.png,.jpg,.jpeg,.pdf'
                    onClick={event => this.handleUploadPoaFile(event)}
                />
                <canvas className='hidden' id='poaCanvas'></canvas>
                <Navigator
                    page={'proof_of_address'}
                    action={this.state.navigation.action}
                    propsToPass={this.state.navigation.props}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        color: state.config.profile.data.company_branding.primary_color,
        title: state.config.profile.data.proof_of_address[0].title,
        subTitle: state.config.profile.data.proof_of_address[0].sub_title,
        isRequired: state.config.profile.data.proof_of_address[0].is_required,
        poaUploadKey: state.config.profile.data.poa_upload_key,
        docCompleted:
            state.submissionState.submissionState.document_upload.completed,
        isDocSubmitted: state.config.isDocSubmitted,
        poaCompleted:
            state.submissionState.submissionState.poa_upload.completed,
        companyName: state.config.profile.data.company_branding.name,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            completeStage,
        },
        dispatch
    );
}

export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(UploadProofOfAddress)
);
