/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import i18n from './../i18n';

// Actions
import { setInstanceID } from './actions/configActions';
import { clearSubmissionState } from './actions/submissionStateActions';

// Services
import DataDogService from '../services/shared/datadogService';
import selectStorage from '../services/shared/selectStorage';

// Components
import Header from './Header';
import Navigator from './Navigator';
import ReCaptchaScreen from './ReCaptchaScreen';
import CustomSelectInput from './Inputs/CustomSelectInput';

// Images
import loadingGif from '../assets/gifs/loading.gif';
import { imageAlt } from '../config/accessabilityRules';

// Config
import LANGUAGES from '../config/languages';
import { ACTION_LABELS } from '../config/dataDogActionLabels';
import {
    areCookiesEnabled,
    getLanguage,
    isInIframe,
} from '../services/shared/helpers';

// Helpers
import { genericErrors } from '../services/shared/helpers';

// Constants
const ACTION = ACTION_LABELS.start;

class AppEntry extends Component {
    constructor(props) {
        super(props);
        this.primaryFocusRef = React.createRef();
        this.loadingRef = React.createRef();
        this.state = {
            inputValue: null,
            uuidExists: false,
            ruuidExists: false,
            brandingExists: null,
            loadingError: null,
            navigation: {
                action: 'load',
                props: null,
            },
        };
    }

    componentDidMount() {
        this.props.clearSubmissionState();

        // Checks that cookies are enabled before querying selectStorage(areCookiesEnabled())
        areCookiesEnabled() &&
            selectStorage(areCookiesEnabled()).removeItem('documentRegion');
        const uuid = areCookiesEnabled()
            ? selectStorage(areCookiesEnabled()).getItem('uuid')
            : null;
        const ruuid = areCookiesEnabled()
            ? selectStorage(areCookiesEnabled()).getItem('ruuid')
            : null;

        if (ruuid !== null) {
            this.setState({
                ruuidExists: true,
            });
        }

        if (uuid !== null && uuid.length) {
            this.setState({
                uuidExists: true,
            });
        }

        DataDogService.initialise();
        this.props.setInstanceID(this);
        areCookiesEnabled();

        this.getErrorMessage();

        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }

        // Sets the document title
        const { t } = this.props;
        if (this.loadingRef && this.loadingRef.current) {
            document.title = t('idpal_doc_title_initialisation');
        } else {
            document.title = t('idpal_doc-title_initialisation_error');
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.uuidValid !== this.props.uuidValid ||
            prevProps.acuantInitialized !== this.props.acuantInitialized ||
            prevProps.limitReached !== this.props.limitReached ||
            prevProps.isBranding !== this.props.isBranding ||
            prevProps.isUuidFromRuuidError !==
                this.props.isUuidFromRuuidError ||
            prevProps.submissionState.submissionState !==
                this.props.submissionState.submissionState
        ) {
            this.getErrorMessage();
        }

        if (prevProps.branding !== this.props.branding) {
            this.setState({
                brandingExists: this.props.branding,
            });
        }
        if (
            areCookiesEnabled() &&
            !selectStorage(areCookiesEnabled()).getItem('ruuid') &&
            this.state.ruuidExists
        ) {
            this.setState({
                ruuidExists: false,
            });
        }

        // Sets the document title
        const { t } = this.props;
        if (!this.state.ruuidExists) {
            if (this.loadingRef && this.loadingRef.current) {
                document.title = t('idpal_doc_title_initialisation');
            } else {
                document.title = t('idpal_doc-title_initialisation_error');
            }
        }
    }

    getErrorMessage() {
        let message = null;

        // Check if we have ruuid, uuid or submissionID in storage
        const isInitWithRuuid =
            selectStorage(areCookiesEnabled()).getItem('ruuid') !== null;
        const isInitWithUuid =
            selectStorage(areCookiesEnabled()).getItem('uuid') !== null;
        const isInitWithSubmissionID =
            selectStorage(areCookiesEnabled()).getItem('submissionid') !== null;

        // First check if cookies are enabled and not in iframe
        if (
            (!areCookiesEnabled() && !isInIframe()) ||
            (!areCookiesEnabled() && isInitWithRuuid)
        ) {
            message = 'idpal_cookie_error';
            this.setState({ loadingError: message });
            return;
        }

        // Check if Ruuid
        if (isInitWithRuuid) {
            // Check we have branding
            if (!this.props.isBranding) {
                message = 'idpal_default_error';
                this.setState({ loadingError: message });
                return;
            }

            // Check we have no error getting uuid
            if (this.props.isUuidFromRuuidError) {
                if (genericErrors()) {
                    message = 'idpal_default_error';
                } else {
                    message = 'idpal_ruuid_error';
                }
                this.setState({ loadingError: message });
                return;
            }
        } else {
            // Check we have a valid uuid
            if (!this.props.uuidValid || !isInitWithUuid) {
                message = 'idpal_uuid_invalid_error';
                this.setState({ loadingError: message });
                return;
            }

            // Check acuant has initialized
            if (
                (this.props.acuantInitialized === false &&
                    this.props.bypassAcuant === false) ||
                !isInitWithSubmissionID
            ) {
                message = 'idpal_initialization_error';
                this.setState({ loadingError: message });
                return;
            }

            // Check we have not reached the uuid limit
            if (this.props.limitReached) {
                message = 'idpal_submission_limit_error';
                this.setState({ loadingError: message });
                return;
            }
        }

        this.setState({ loadingError: message });
    }

    render() {
        const { t } = this.props;

        // Applied loading screen, before getting branding details
        if (this.props.pendingItems > 0) {
            return (
                <div className='u-text-center start-loading'>
                    <img
                        alt={imageAlt.loading}
                        src={loadingGif}
                        className='capture'
                        ref={this.loadingRef}
                    />
                </div>
            );
        }

        return (
            <Fragment>
                <Header />
                <div className='o-site-wrap'>
                    {this.state.loadingError && (
                        <Fragment>
                            <div className='u-generic-text u-text-center'>
                                <div className='alert-no-uuid'>
                                    <h1 ref={this.primaryFocusRef} tabIndex={0}>
                                        {t(this.state.loadingError)}
                                        {this.props.isUuidFromRuuidError &&
                                            !genericErrors() && (
                                                <span>
                                                    {
                                                        this.props.branding.data
                                                            .company_branding
                                                            .costumer_support_email
                                                    }
                                                </span>
                                            )}
                                    </h1>
                                </div>

                                <div className='u-generic-text  u-text-center languageSelection'>
                                    <label>{t('idpal_language')}:</label>
                                    <CustomSelectInput
                                        defaultText={getLanguage(i18n.language)}
                                        optionsList={LANGUAGES}
                                        isLanguageChange={true}
                                        actionDataLabel={ACTION.languageSelect}
                                        labelHeader={t(
                                            'idpal_label_select_language'
                                        )}
                                        elementIdToFocus={i18n.language}
                                    />
                                </div>
                            </div>

                            <Navigator
                                page={'start_screen'}
                                action={this.state.navigation.action}
                                propsToPass={this.state.navigation.props}
                            />
                        </Fragment>
                    )}

                    {/*load recaptcha screen when ruuid*/}
                    {this.state.ruuidExists && !this.state.loadingError && (
                        <Fragment>
                            {DataDogService.log(
                                'Application Initialised Successfully.'
                            )}
                            <ReCaptchaScreen />
                        </Fragment>
                    )}

                    {!this.state.ruuidExists &&
                        !this.props.isInitWithRuuid &&
                        !this.state.loadingError &&
                        this.props.instanceID &&
                        this.state.brandingExists && (
                            <Fragment>
                                {DataDogService.log(
                                    'Application Initialised Successfully.'
                                )}
                                <Navigator page={'app_entry'} action={'next'} />
                            </Fragment>
                        )}

                    {/*skip start screen when ruuid*/}
                    {!this.state.ruuidExists &&
                        this.props.isInitWithRuuid &&
                        !this.state.loadingError &&
                        this.props.instanceID &&
                        this.state.brandingExists && (
                            <Fragment>
                                {DataDogService.log(
                                    'Application Initialised Successfully.'
                                )}
                                <Navigator
                                    page={'start_screen'}
                                    action={'next'}
                                />
                            </Fragment>
                        )}
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        acuantInitialized: state.config.acuantInitialized,
        bypassAcuant: state.config.bypassAcuant,
        branding: state.config.profile,
        isBranding: state.config.isBranding,
        uuidValid: state.config.uuidValid,
        isUuidFromRuuidError: state.config.isUuidFromRuuidError,
        isRuuid: state.config.isRuuid,
        isInitWithRuuid: state.config.isInitWithRuuid,
        limitReached: state.config.limitReached,
        pendingItems: state.spinner.pendingItems,
        submissionState: state.submissionState,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setInstanceID,
            clearSubmissionState,
        },
        dispatch
    );
}

export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(AppEntry)
);
