/**
 * @prettier
 */

import { areCookiesEnabled } from './helpers';

/**
 * Selects whether to use persistent storage
 *
 * @param {Boolean} persistStorage
 * @returns
 */
export default function selectStorage(persistStorage = true) {
    // storage methods
    const persist = areCookiesEnabled() && window.sessionStorage;
    const globalStore = {};

    // set window global store methods
    globalStore.setItem = function (key, value) {
        if (window?.appData === undefined) {
            return;
        }

        window.appData[key] = value;
    };

    globalStore.getItem = function (key) {
        if (window?.appData === undefined) {
            return;
        }

        if (window.appData[key] === undefined) {
            return null;
        }

        return window.appData[key];
    };

    globalStore.removeItem = function (key) {
        if (window?.appData === undefined) {
            return;
        }

        if (window.appData[key] === undefined) {
            return;
        }

        delete window.appData[key];
    };

    // Select store method
    const selectStoreMethod = () => {
        if (persistStorage) {
            return persist;
        } else {
            return globalStore;
        }
    };

    // Set store method
    const storeMethod = selectStoreMethod();

    /**
     *
     * @param {String} key
     * @param {String} value
     */
    const setItem = (key, value) => {
        storeMethod.setItem(key, value);
    };

    /**
     * Gets item from storage
     *
     * @param {String} key
     * @param {String} value
     * @returns {String} - Value from storage
     */
    const getItem = (key, value) => {
        return storeMethod.getItem(key, value);
    };

    /**
     * Removes item from storage
     *
     * @param {String} key
     */
    const removeItem = key => {
        storeMethod.removeItem(key);
    };

    return {
        setItem,
        getItem,
        removeItem,
    };
}
