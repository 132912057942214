/**
 * @prettier
 */

/**
 * Defining form validation rules
 *
 * @type object
 */
let eVerification = {
    firstName: {
        name: 'idpal_first_name',
        id: 'firstName',
        required: true,
        min: 1,
        max: 50,
        regex: "^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'’-]+$",
        messages: {
            regex: 'idpal_allowed_characters_letters_error',
        },
    },
    lastName: {
        name: 'idpal_last_name',
        id: 'lastName',
        required: true,
        min: 1,
        max: 50,
        regex: "^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'’-]+$",
        messages: {
            regex: 'idpal_allowed_characters_letters_error',
        },
    },
    dob: {
        name: 'idpal_dob',
        type: 'date',
        minDate: '1900-01-01',
        maxDate: '',
        id: 'dob',
        required: true,
    },
    phone: {
        name: 'idpal_phone',
        type: 'phone',
        required: true,
    },
    address1: {
        name: 'idpal_address_1',
        id: 'address1',
        required: true,
        min: 1,
        max: 50,
        regex: "^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'’-]+$",
        messages: {
            regex: 'idpal_allowed_characters_letters_numbers_error',
        },
    },
    address2: {
        name: 'idpal_address_2',
        id: 'address2',
        required: false,
        min: 1,
        max: 50,
        regex: "^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'’-]+$",
        messages: {
            regex: 'idpal_allowed_characters_letters_numbers_error',
        },
    },
    city: {
        name: 'idpal_city',
        id: 'city',
        required: true,
        min: 1,
        max: 50,
        regex: "^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'’-]+$",
        messages: {
            regex: 'idpal_allowed_characters_letters_numbers_error',
        },
    },
    postCode: {
        name: 'idpal_postcode',
        id: 'postCode',
        required: true,
        min: 1,
        max: 25,
        regex: "^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'’-]+$",
        messages: {
            regex: 'idpal_allowed_characters_letters_numbers_error',
        },
    },
    country: {
        name: 'idpal_country',
        id: 'country',
        placeholder: 'Select',
        required: true,
        optionValue: 'iso_3166_3',
        optionName: 'name',
    },
};

export { eVerification };
