/**
 * @prettier
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class InfoTip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: '',
            showInfoTip: false,
            displayInfoTip: {
                display: 'none',
            },
        };
        this.showInfoTip = this.showInfoTip.bind(this);
        this.hideInfoTip = this.hideInfoTip.bind(this);
        this.handleTransitionEnd = this.handleTransitionEnd.bind(this);
    }

    componentDidMount() {
        this.setState({
            color: this.props.branding.data.company_branding.primary_color
                ? this.props.branding.data.company_branding.primary_color
                : this.state.color,
        });
    }

    hideInfoTip() {
        this.setState({
            showInfoTip: false,
        });
    }

    showInfoTip() {
        this.setState({
            showInfoTip: true,
            displayInfoTip: {
                display: 'block',
            },
        });
        // Auto hide after 5 seconds
        setTimeout(this.hideInfoTip, 5000);
    }

    handleTransitionEnd() {
        if (this.state.showInfoTip === false) {
            this.setState({
                displayInfoTip: {
                    display: 'none',
                },
            });
        }
    }

    render() {
        const color = this.state.color
            ? {
                  backgroundColor: '#' + this.state.color,
              }
            : {};

        return (
            <>
                <div
                    className='info-tip'
                    onClick={this.showInfoTip}
                    onMouseEnter={this.showInfoTip}
                    style={color}
                >
                    <span>𝗶</span>

                    <div
                        className={
                            this.state.showInfoTip
                                ? 'info-bubble show'
                                : 'info-bubble'
                        }
                        onTransitionEnd={this.handleTransitionEnd}
                        style={this.state.displayInfoTip}
                    >
                        {this.props.message}
                    </div>
                </div>
                <div
                    className={
                        this.state.showInfoTip
                            ? 'info-tip-screen show'
                            : 'info-tip-screen'
                    }
                    onClick={this.hideInfoTip}
                ></div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        branding: state.config.profile,
    };
}

export default withTranslation('translation')(
    connect(mapStateToProps)(InfoTip)
);
