/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { countError } from '../actions/configActions';
import i18next from 'i18next';

// Components
import CustomButton from '../Button';

// Helpers
import { hideReconnecting, genericErrors } from '../../services/shared/helpers';

// Config
import { ACTION_LABELS } from '../../config/dataDogActionLabels';
const ACTION = ACTION_LABELS.errors;

class Error extends Component {
    constructor(props) {
        super(props);
        this.primaryFocusRef = React.createRef();
        this.state = {
            max: 3, //Max allowed retries
        };
    }

    componentDidMount() {
        // Count how many times we've encountered an error this session
        this.props.countError();

        // Hide reconnecting message if shown
        hideReconnecting();

        // Set document title
        const { t } = this.props;
        document.title = t('idpal_doc_title_error');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }

        // Set document title
        const { t } = this.props;
        document.title = t('idpal_doc_title_error');
    }

    isNetworkError() {
        if (this.props.error != null) {
            if (this.props.error.message === 'Network Error') {
                return true;
            }
        }
        return false;
    }

    allowRetry() {
        if (!this.props.retryAction) {
            return false;
        }

        if (this.props.errors > this.state.max && !this.isNetworkError()) {
            return false;
        }
        return true;
    }

    getContact() {
        if (this.props.branding) {
            return this.props.branding.data.company_branding
                .costumer_support_email;
        } else {
            return i18next.t('idpal_an_administrator');
        }
    }

    render() {
        const { t } = this.props;

        return (
            <Fragment>
                <div className={'error-overlay'}>
                    <div className='o-site-wrap'>
                        {/* Max retries not hit, and we have a function to retry, allow user to retry */}
                        {this.allowRetry() &&
                            (this.isNetworkError() ||
                                !this.props.isLimitReached) && (
                                <Fragment>
                                    <h1
                                        className='large-text u-text-center u-btm-buffer'
                                        ref={this.primaryFocusRef}
                                        tabIndex={0}
                                    >
                                        {this.isNetworkError()
                                            ? t('idpal_network_error')
                                            : t(
                                                  'idpal_application_encountered_an_error'
                                              ) + t('idpal_please_try_again')}
                                    </h1>

                                    <CustomButton
                                        id={'retry'}
                                        label={t('idpal_try_again')}
                                        className={'btn'}
                                        handleClick={this.props.retryAction}
                                        actionDataLabel={
                                            ACTION.errorRetryButton
                                        }
                                    />
                                </Fragment>
                            )}

                        {/* Max retries hit, or no function to retry, contact admin */}
                        {(!this.allowRetry() ||
                            (this.props.isLimitReached &&
                                !this.isNetworkError())) && (
                            <Fragment>
                                <h1
                                    className='large-text u-text-center u-btm-buffer'
                                    ref={this.primaryFocusRef}
                                    tabIndex={0}
                                >
                                    {t(
                                        'idpal_application_encountered_an_error'
                                    )}
                                </h1>

                                {!genericErrors() && (
                                    <p className='sub-text u-text-center'>
                                        {t('idpal_please_contact', {
                                            contact: this.getContact(),
                                        })}
                                    </p>
                                )}
                            </Fragment>
                        )}
                    </div>
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        branding: state.config.profile,
        errors: state.submissionState.errors,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ countError }, dispatch);
}

export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(Error)
);
