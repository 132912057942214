/**
 * @prettier
 */

import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: false,
        lng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            wait: true,
            useSuspense: false,
        },
        backend: {
            loadPath:
                process.env.REACT_APP_BASENAME +
                'locales/{{lng}}/{{ns}}.json?v=20241120',
        },
    });

export default i18n;
