/**
 * @prettier
 */

import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { bindActionCreators } from 'redux';

// Helpers
import {
    updateUrlLanguageParam,
    swapElementsOfArray,
} from '../../services/shared/helpers';

// Actions
import { setInitializationParams } from '../actions/configActions';

class CustomSelectInput extends React.Component {
    constructor(props) {
        super(props);
        this.selectOptionRef = React.createRef();
        this.state = {
            defaultSelectText: '',
            showOptionList: false,
            isFocusSet: false,
            currentFocus: 0,
            optionsList: [],
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleListDisplay = this.handleListDisplay.bind(this);
        this.handleOptionClick = this.handleOptionClick.bind(this);
        this.handleMouseEnterSelect = this.handleMouseEnterSelect.bind(this);
        this.handleOptionKeyDown = this.handleOptionKeyDown.bind(this);
    }

    componentDidMount() {
        // Adds Event listener to handle clicking outside the container
        document.addEventListener('mousedown', this.handleClickOutside);

        // Sets option dropdown button text
        this.setState({ defaultSelectText: this.props.defaultText });

        // Orders options list so that selected element always displays as first option
        this.setState({ optionsList: this.orderOptionsList() });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.showOptionList !== prevState.showOptionList) {
            if (this.state.showOptionList) {
                // Sets focus to dropdown element on render
                this.toggleFocus();
                this.setState({ isFocusSet: true });
            } else {
                // Removes and resets focus
                this.setState({ isFocusSet: false });
                this.setState({ currentFocus: 0 });
            }
        }
        // Orders options list so that selected element always displays as first option
        if (this.props.elementIdToFocus !== prevProps.elementIdToFocus) {
            this.setState({ optionsList: this.orderOptionsList() });
        }
    }

    // Removes listeners when component unmounts
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    // Handles closing the dropdown when clicked outside of the select
    handleClickOutside(event) {
        if (
            !event.target.classList.contains('custom-select-btn') &&
            !event.target.classList.contains('selected-text')
        ) {
            this.setState({ showOptionList: false });
            this.setState({ selected: true });
        }
    }

    // Handles removing focus background on mouse over
    handleMouseEnterSelect(event) {
        const dropdownOptions = document.querySelectorAll('.custom-select-btn');
        dropdownOptions.forEach(option => {
            option.blur();
        });
    }

    // Handles displaying of the option list
    handleListDisplay() {
        this.setState(prevState => {
            return {
                showOptionList: !prevState.showOptionList,
            };
        });
    }

    // Handles down arrow and up arrow focus change for options dropdown
    handleOptionKeyDown(event) {
        const dropdownButtons = document.querySelectorAll('.custom-select-btn');
        if (event.key === 'ArrowDown') {
            let currentFocus = this.state.currentFocus;
            let elementInFocus = dropdownButtons[currentFocus];
            let elementToFocus = dropdownButtons[currentFocus + 1];
            if (elementToFocus) {
                elementToFocus.focus();
                elementInFocus.blur();
                currentFocus++;
                this.setState({ currentFocus: currentFocus });
            }
        }
        if (event.key === 'ArrowUp') {
            let currentFocus = this.state.currentFocus;
            let elementInFocus = dropdownButtons[currentFocus];
            let elementToFocus = dropdownButtons[currentFocus - 1];
            if (elementToFocus) {
                elementToFocus.focus();
                elementInFocus.blur();
                currentFocus--;
                this.setState({ currentFocus: currentFocus });
            }
        }
    }

    handleOptionClick(event) {
        // Sets option name in select text area
        this.setState({
            defaultSelectText: event.target.getAttribute('data-name'),
            showOptionList: false,
        });

        // Resets focus and focus styles
        this.setState({ selected: true });
        this.selectOptionRef.current.focus();

        // Updates language if passed through props
        if (this.props.isLanguageChange) {
            const language = event.target.getAttribute('value');

            // Sets the new url param
            updateUrlLanguageParam(language);

            // Updates the init param
            this.props.setInitializationParams(window.location.search);

            // Changes language
            i18n.changeLanguage(language);
        }
    }

    // Orders options list so that selected element always displays as first option
    orderOptionsList() {
        const orderedOptionsList = this.props.optionsList;
        let optionIndex = null;
        this.props.optionsList.some((option, index) => {
            if (option.value === this.props.elementIdToFocus) {
                return (optionIndex = index);
            }
            return null;
        });

        swapElementsOfArray(orderedOptionsList, 0, optionIndex);
        return orderedOptionsList;
    }

    // Toggles focus / blur of selected dropdown element
    toggleFocus() {
        if (this.state.showOptionList) {
            if (this.state.isFocusSet) {
                setTimeout(() => {
                    const element = document.querySelector(
                        `#${this.props.elementIdToFocus}`
                    );
                    element && element.blur();
                }, 50);
            } else {
                setTimeout(() => {
                    const element = document.querySelector(
                        `#${this.props.elementIdToFocus}`
                    );
                    element && element.focus();
                }, 50);
            }
        }
    }

    render() {
        const { optionsList, labelHeader, actionDataLabel } = this.props;
        const { showOptionList, defaultSelectText } = this.state;

        return (
            <div className='custom-select-container'>
                <button
                    className={
                        showOptionList
                            ? 'selected-text active'
                            : 'selected-text'
                    }
                    onClick={this.handleListDisplay}
                    ref={this.selectOptionRef}
                    aria-label={labelHeader}
                >
                    {defaultSelectText}
                    <span
                        className={
                            showOptionList ? 'chevron up' : 'chevron down'
                        }
                    ></span>
                </button>

                {showOptionList && (
                    <ul
                        className='select-options'
                        onMouseEnter={this.handleMouseEnterSelect}
                    >
                        {optionsList.map(option => {
                            return (
                                <li
                                    key={option.value}
                                    className='option'
                                    onKeyDown={this.handleOptionKeyDown}
                                >
                                    <button
                                        className='custom-select-btn'
                                        data-name={option.label}
                                        value={option.value}
                                        onClick={this.handleOptionClick}
                                        data-dd-action-name={actionDataLabel}
                                        id={option.value}
                                        aria-label={`Select ${option.label}`}
                                    >
                                        {option.label}
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setInitializationParams }, dispatch);
}

export default withTranslation('translation')(
    connect(null, mapDispatchToProps)(CustomSelectInput)
);
