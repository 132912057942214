/**
 * @prettier
 */

export function setCardType(payload) {
    return {
        type: '@@acuant/SET_ID_TYPE',
        data: payload,
    };
}

export function setCardOrientation(payload) {
    return {
        type: '@@acuant/SET_ORIENTATION',
        data: payload,
    };
}

export function decrementSidesLeft() {
    return {
        type: '@@acuant/DECREMENT_SIDE',
    };
}

export function incrementSidesLeft() {
    return {
        type: '@@acuant/INCREMENT_SIDE',
    };
}

export function resetIDProperties() {
    return {
        type: '@@acuant/RESET_ID',
    };
}

/**
 *
 * @param {*} payload - Data to be saved to the store
 * @param {String} type - Image type - (cropped, uncropped, downscaled)
 * @returns {Object}
 */
export function setCapturedDocumentImage(payload, imageType = 'cropped') {
    if (imageType === 'uncropped') {
        return {
            type: 'SET_UNCROPPED_IMAGE',
            data: payload,
        };
    }

    if (imageType === 'downscaled') {
        return {
            type: 'SET_DOWNSCALED_IMAGE',
            data: payload,
        };
    }

    if (imageType === 'cropped') {
        return {
            type: 'SET_CROPPED_IMAGE',
            data: payload,
        };
    }
}

export function isExpiredTwoPagePassport(payload) {
    return {
        type: 'IS_EXPIRED_TWO_PAGE_PASSPORT',
        data: payload,
    };
}

export function setUserSelectedCardType(payload) {
    return {
        type: 'USER_SELECTED_CARD_TYPE',
        data: payload,
    };
}
