/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

// Actions
import {
    checkLoadState,
    navigateBack,
    navigateNext,
} from './actions/navigatorActions';

// Components
import Error from './Error/Error';

class Navigator extends Component {
    componentDidMount() {
        if (this.props.action === 'next') {
            navigateNext(
                this.props.page,
                this.props.submissionState,
                this.props.propsToPass,
                this.props.params
            );
        }

        if (this.props.action === 'load') {
            checkLoadState(
                this.props.page,
                this.props.submissionState,
                this.props.propsToPass
            );
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.action !== this.props.action) {
            if (this.props.action === 'next') {
                navigateNext(
                    this.props.page,
                    this.props.submissionState,
                    this.props.propsToPass,
                    this.props.params
                );
            }

            if (this.props.action === 'back') {
                navigateBack(
                    this.props.page,
                    this.props.submissionState,
                    this.props.propsToPass,
                    this.props.params
                );
            }
        }
    }

    render() {
        return (
            <Fragment>
                {this.props.action === 'error' && this.props.propsToPass && (
                    <Error
                        retryAction={this.props.propsToPass.retryAction}
                        error={this.props.propsToPass.error}
                    />
                )}
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        submissionState: state.submissionState,
        params: state.config.initializationParams,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            navigateNext,
        },
        dispatch
    );
}

export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(Navigator)
);
