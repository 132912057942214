/**
 * @prettier
 */

// Sends message to iframe parent
function postMessageToParent(message, target) {
    // Only send message if embedded
    if (window.self !== window.top) {
        window.parent.postMessage(message, target);
    }
}

// Sends success message
export function sendSuccessMessage(target) {
    postMessageToParent('idpal_success', target);
}
