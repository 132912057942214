/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import PhoneInput from 'react-phone-input-2';

// Actions
import { sendSmsLink } from './actions/mobileHandoffActions';
import { getCountries } from './actions/eVerificationActions';

// Services
import selectStorage from '../services/shared/selectStorage';
import { areCookiesEnabled } from '../services/shared/helpers';

// Other packages
import { phone } from 'phone';
import 'react-phone-input-2/lib/style.css';
import QRCode from 'react-qr-code';

// Components
import Header from './Header';
import CustomButton from './Button';
import Label from './Inputs/Label';

// Images
import loadingGif from '../assets/gifs/loading.gif';

// Config
import { hiddenFormLabels, imageAlt } from '../config/accessabilityRules';
import { ACTION_LABELS } from '../config/dataDogActionLabels';
const ACTION = ACTION_LABELS.handoff;

class MobileHandoff extends Component {
    constructor(props) {
        super(props);
        this.primaryFocusRef = React.createRef();
        this.resendFocusRef = React.createRef();
        this.formRef = React.createRef();
        this.state = {
            phone: null,
            isPhoneInputDirty: false,
            linkSent: false,
            color: '',
            error: null,
            uuid: '',
            lang: 'en',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.sendAgain = this.sendAgain.bind(this);
        this.setPhone = this.setPhone.bind(this);
        this.setPhoneInputAriaLabel = this.setPhoneInputAriaLabel.bind(this);
    }

    componentDidMount() {
        this.setState({
            color: this.props.branding.data.company_branding.primary_color
                ? this.props.branding.data.company_branding.primary_color
                : this.state.color,
            phone: this.props.phone,
            uuid: selectStorage(areCookiesEnabled()).getItem('uuid'),
            lang: selectStorage(areCookiesEnabled()).getItem('lang'),
        });

        if (this.props.phone === null) {
            // If we don't have a phone number, get current country to default input
            this.props.getCountries();
        }

        // Sets document title
        const { t } = this.props;
        document.title = t('idpal_doc_title_handoff');

        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Sets focus to primary heading on first render
        if (
            !this.state.isPhoneInputDirty &&
            this.primaryFocusRef &&
            this.primaryFocusRef.current
        ) {
            this.primaryFocusRef.current.focus();
        }

        if (
            this.state.linkSent &&
            this.resendFocusRef &&
            this.resendFocusRef.current
        ) {
            this.resendFocusRef.current.focus();
        }

        // Sets document title
        const { t } = this.props;
        if (this.state.linkSent) {
            document.title = t('idpal_doc_title_handoff_resend');
        } else {
            document.title = t('idpal_doc_title_handoff');
        }
        if (this.state.error) {
            this.setFocusOnError();
        }
    }

    validate() {
        let error = null;
        if (!phone('+' + this.state.phone).isValid) {
            error = i18next.t('idpal_please_enter_valid_number');
        }
        return error;
    }

    sendAgain() {
        // Put back uuid in session storage to make request again
        this.setState((state, props) => {
            return {
                linkSent: false,
                isPhoneInputDirty: false,
            };
        });
    }

    buildHandoffUrl() {
        return (
            window.location.protocol +
            '//' +
            window.location.host +
            '?uuid=' +
            this.state.uuid +
            '&handoff_session=' +
            selectStorage(areCookiesEnabled()).getItem('sessionid') +
            '&lang=' +
            selectStorage(areCookiesEnabled()).getItem('lang') +
            '&target=webapp_mobile'
        );
    }

    setPhone(value, data, event, formattedValue) {
        if (data.dialCode) {
            if (
                value.slice(0, data.dialCode.length) !== data.dialCode &&
                value.length > 5
            ) {
                value = data.dialCode + value;
            }
        }
        this.setState((state, props) => {
            return {
                phone: value,
                isPhoneInputDirty: true,
            };
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        let error = this.validate();

        this.setState((state, props) => {
            return { error: error };
        });

        if (!error) {
            let data = {
                phone: this.state.phone,
                uuid: this.state.uuid,
                lang: this.state.lang,
            };
            this.props.sendSmsLink(data);
            this.setState((state, props) => {
                return { linkSent: true };
            });
        }
    }

    // Set hidden error label for screen readers
    setPhoneInputAriaLabel() {
        let ariaLabel = `${hiddenFormLabels.mobile} ${hiddenFormLabels.handoff}`;
        if (this.state.error) {
            ariaLabel = `${hiddenFormLabels.mobile} ${this.state.error}`;
        }
        return ariaLabel;
    }

    setFocusOnError() {
        if (this.state.error) {
            this.formRef.current[0].focus();
        }
    }

    render() {
        const { t } = this.props;

        const textColor = this.state.color
            ? {
                  color: '#' + this.state.color,
              }
            : {};

        // Awaiting pending
        if (this.props.pendingItems > 0) {
            return (
                <div className='u-text-center start-loading'>
                    <img
                        alt={imageAlt.loading}
                        src={loadingGif}
                        className='capture'
                    />
                </div>
            );
        }

        if (this.state.linkSent === false) {
            return (
                <Fragment>
                    <Header />

                    <div className='o-site-wrap handoff'>
                        <div className=''>
                            <h1 ref={this.primaryFocusRef} tabIndex={0}>
                                {t('idpal_two_ways_to_verify_your_identity')}
                            </h1>
                            <h2 className={'u-text-center'}>
                                {t('idpal_verify_identity_on_mobile_device')}
                            </h2>
                        </div>

                        <div className='container'>
                            <div className='handoff-option'>
                                <h3 className={'u-text-center'}>
                                    {t('idpal_send_link_to_phone')}
                                </h3>

                                <form
                                    onSubmit={this.handleSubmit}
                                    className={'phone'}
                                    ref={this.formRef}
                                >
                                    <div
                                        className={
                                            this.state.error
                                                ? 'error input-group'
                                                : 'input-group'
                                        }
                                    >
                                        <Label
                                            id={'phone'}
                                            required={false}
                                            label={t('idpal_phone')}
                                        />
                                        <PhoneInput
                                            enableSearch
                                            disableSearchIcon
                                            enableLongNumbers
                                            country={
                                                this.props.initialCountry
                                                    ? this.props.initialCountry.toLowerCase()
                                                    : ''
                                            }
                                            inputClass={'phone-input'}
                                            placeholder={this.props.phone}
                                            value={this.state.phone}
                                            onChange={this.setPhone}
                                            inputProps={{
                                                id: 'phone',
                                                'aria-label':
                                                    this.setPhoneInputAriaLabel(),
                                                'aria-invalid': this.state.error
                                                    ? true
                                                    : false,
                                                'data-dd-action-name':
                                                    ACTION.phoneInput,
                                            }}
                                        />

                                        {this.state.error && (
                                            <span className='error-message'>
                                                {this.state.error}
                                            </span>
                                        )}
                                    </div>

                                    <CustomButton
                                        className='btn'
                                        label={t('idpal_send')}
                                        actionDataLabel={ACTION.sendLinkButton}
                                    />
                                </form>
                            </div>
                            <div className='handoff-divider'>OR</div>
                            <div className='handoff-option'>
                                <h3 className={'u-text-center'}>
                                    {t('idpal_scan_qr_code')}
                                </h3>
                                <QRCode
                                    className='qr'
                                    value={this.buildHandoffUrl()}
                                    size={200}
                                    title='Scan QR Code'
                                />
                                <p className='u-text-center qr-message'>
                                    {t('idpal_qr_message')}
                                </p>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        }
        if (this.state.linkSent === true) {
            return (
                <Fragment>
                    <Header />

                    <div className='o-site-wrap handoff'>
                        <h1
                            className={'u-text-center'}
                            ref={this.resendFocusRef}
                            tabIndex={0}
                        >
                            {t('idpal_check_sms')}
                        </h1>
                        <h2 className={'u-text-center'}>
                            {t('idpal_didnt_receive_link')}
                            <button
                                className='btn-link font-normalize'
                                style={textColor}
                                onClick={this.sendAgain}
                                data-dd-action-name={ACTION.resendLinkButton}
                            >
                                {t('idpal_send_again')}
                            </button>
                        </h2>
                    </div>
                </Fragment>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        branding: state.config.profile,
        uuidValid: state.config.uuidValid,
        limitReached: state.config.limitReached,
        pendingItems: state.spinner.pendingItems,
        phone: state.eVerification.phone,
        initialCountry: state.eVerification.initialCountry,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            sendSmsLink,
            getCountries,
        },
        dispatch
    );
}

export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(MobileHandoff)
);
