/**
 * @prettier
 */

export function awaitResponse() {
    return {
        type: 'PENDING',
    };
}

export function responseReceived() {
    return {
        type: 'COMPLETE',
    };
}
