/**
 * @prettier
 */

const LANGUAGES = [
    { label: 'UK-English', value: 'en' },
    { label: 'US-English', value: 'en-US' },
    { label: 'Français', value: 'fr' },
    { label: 'Español', value: 'es' },
    { label: 'Italiano', value: 'it' },
];

export default LANGUAGES;
