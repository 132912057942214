/**
 * @prettier
 */

// React Packages
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

// Components
import Label from './Label';

// Config
import { focusInputIds } from '../../config/accessabilityRules';

class TextInput extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        // Resets the focus on the current text input if the component remounts
        // Rest to address look up
        if (
            (this.props.config.id === focusInputIds.addressLookup &&
                this.props.isAutoAddressFocus) ||
            (this.props.config.id === focusInputIds.addressLine1 &&
                this.props.isAutoAddressFocus) ||
            (this.props.config.id === focusInputIds.firstName &&
                !this.props.isAutoAddressFocus)
        ) {
            this.inputRef.current.focus();
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div
                className={
                    this.props.error ? 'error input-group' : 'input-group'
                }
            >
                <Label
                    id={this.props.config.id}
                    required={this.props.config.required}
                    label={this.props.config.name}
                    infoTip={this.props.infoTip}
                    errorMessage={this.props.error}
                    data-dd-action-name={this.props.actionDataLabel}
                />
                <input
                    type='text'
                    id={this.props.config.id}
                    onChange={this.props.onChange}
                    placeholder={t(this.props.placeholder)}
                    autoComplete={this.props.autocomplete}
                    value={this.props.value}
                    ref={this.inputRef}
                    aria-invalid={this.props.error ? true : false}
                />

                {this.props.error && (
                    <span className='error-message'>{this.props.error}</span>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        branding: state.config.profile,
        isAutoAddressFocus: state.eVerification.isAutoAddressFocus,
    };
}

export default withTranslation('translation')(
    connect(mapStateToProps)(TextInput)
);
