/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { setInstanceID } from './actions/configActions';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logo: this.props.branding
                ? this.props.branding.data.company_branding.logo
                : null,
        };
    }

    render() {
        let { logo } = this.state;
        return (
            <Fragment>
                {this.props.showHeader === true && (
                    <header className='mastHead'>
                        {logo ? (
                            <div className='logo'>
                                <img
                                    alt='app logo'
                                    className='image'
                                    src={`data:image/png;base64,${logo}`}
                                />
                            </div>
                        ) : (
                            <div className='logo default-logo' />
                        )}
                    </header>
                )}
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        branding: state.config.profile,
        showHeader: state.config.showHeader,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setInstanceID }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
