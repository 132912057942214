/**
 * @prettier
 */

import ApiService from '../../services/api/api';

export function sendSmsLink(data) {
    return dispatch => {
        dispatch({
            type: 'PENDING',
        });
        ApiService.postSendHandoffSms(data).then(response => {
            dispatch({
                type: 'COMPLETE',
            });
        });
    };
}
