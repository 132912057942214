/**
 * @prettier
 */

/* eslint-disable no-unused-vars */
import { datadogRum } from '@datadog/browser-rum';

// Services
import logService from './logService';
import ApiService from '../api/api';
import { areCookiesEnabled, isInIframe } from './helpers';
import selectStorage from './selectStorage';

/**
 * Initialises DataDog
 *
 */
function initialise() {
    if (process.env.REACT_APP_DATADOG_ENABLED === 'true') {
        ApiService.getDataDogCredentials().then(response => {
            if (response.data) {
                datadogRum.init({
                    applicationId: response.data.app_id,
                    clientToken: response.data.token,
                    site: process.env.REACT_APP_DATADOG_SITE,
                    service: process.env.REACT_APP_DATADOG_SERVICE,
                    version: '1.0.0',
                    env: process.env.REACT_APP_ENV,
                    sessionSampleRate: 100,
                    sessionReplaySampleRate: 0,
                    trackUserInteractions: true,
                    trackResources: true,
                    trackLongTasks: true,
                    defaultPrivacyLevel: 'mask',
                    useCrossSiteSessionCookie: isInIframe(),
                });
                logService.log('DataDog initialised.');
            } else {
                logService.error('Could not initalise DataDog.');
            }
        });
    }
}

function getLogBody() {
    return {
        uuid: selectStorage(areCookiesEnabled()).getItem('uuid'),
        submission_id:
            selectStorage(areCookiesEnabled()).getItem('submissionid'),
        integration: isInIframe() ? 'embedded' : 'standalone',
    };
}

function log(message) {
    datadogRum.addAction(message, getLogBody());
}

function createError(message) {
    this.error(new Error(message));
}

function error(error) {
    datadogRum.addError(error, getLogBody());
}

const DataDogService = {
    initialise,
    log,
    error,
    createError,
};

export default DataDogService;
