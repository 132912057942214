/**
 * @prettier
 */

// React Packages
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Components
import Label from './Label';

class SelectInput extends Component {
    render() {
        return (
            <div
                className={
                    this.props.error ? 'error input-group' : 'input-group'
                }
            >
                <Label
                    id={this.props.config.id}
                    required={this.props.config.required}
                    label={this.props.config.name}
                />

                <select
                    id={this.props.config.id}
                    onChange={this.props.onChange}
                    onBlur={this.props.onBlur}
                    value={this.props.default}
                    data-dd-action-name={this.props.actionDataLabel}
                >
                    <option value='' disabled={'disabled'}>
                        {this.props.config.placeholder}
                    </option>

                    {this.props.options.map(option => (
                        <option
                            key={option[this.props.config.optionValue]}
                            value={option[this.props.config.optionValue]}
                        >
                            {option[this.props.config.optionName]}
                        </option>
                    ))}
                </select>

                {this.props.error && (
                    <span className='error-message'>{this.props.error}</span>
                )}
            </div>
        );
    }
}

export default connect()(SelectInput);
