/**
 * @prettier
 */

/**
 * Defining address lookup verification rules
 *
 * @type object
 */
let addressLookup = {
    address1: {
        name: 'idpal_address_1',
        id: 'address1',
        required: true,
    },
    address2: {
        name: 'idpal_address_2',
        id: 'address2',
        required: false,
    },
    locality: {
        name: 'idpal_city',
        id: 'city',
        required: true,
    },
    postalCode: {
        name: 'idpal_postcode',
        id: 'postCode',
        required: true,
    },
};

export { addressLookup };
