/**
 * @prettier
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { history } from '../store';

// Actions
import {
    decrementSidesLeft,
    setCardOrientation,
    setCardType,
} from './actions/idPropertiesActions';
import {
    setInstanceID,
    submitBackID,
    submitFrontID,
} from './actions/configActions';

// Services
import { isIdPalCameraEnabled } from '../services/shared/helpers.js';

// Components
import Header from './Header';
import CustomButton from './Button';
import Navigator from './Navigator';
import Processing from './Processing';
import OutOfRecaptures from './Messages/OutOfRecaptures';

// Images
import dlFrontJpg from '../assets/img/dl_card_front.jpg';
import dlBackJpg from '../assets/img/dl_card_back.jpg';
import idCardBackJpg from '../assets/img/id_card_back.jpg';
import idCardFrontJpg from '../assets/img/id_card_back.jpg';

// Config
import { ACTION_LABELS } from '../config/dataDogActionLabels';
const ACTION = ACTION_LABELS.capturePhoto;

class CapturePhoto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: null,
            processing: false,
            navigation: {
                action: 'load',
                props: null,
            },
        };
    }

    isIEorEDGE() {
        return (
            navigator.appName === 'Microsoft Internet Explorer' ||
            (navigator.appName === 'Netscape' &&
                navigator.appVersion.indexOf('Edge') > -1)
        );
    }

    componentDidMount() {
        // if (!this.props.instanceID) {
        //     this.props.setInstanceID();
        // }

        if (
            this.props.submissionAttempts.remaining <= 0 &&
            this.props.sidesLeft === 0 &&
            this.props.submissionAttempts[
                this.props.sidesLeft === 2 ? 'front' : 'back'
            ] > 0
        ) {
            return;
        } else if (this.props.location && this.props.location.state) {
            if (this.props.location.state.isRetry) {
                this.navigateCamera();
            }
        }

        // Sets document title
        const { t } = this.props;
        document.title = t('idpal_doc_title_capture');
    }

    getOrientationCopy() {
        const { t } = this.props;
        return this.props.orientation === 0 ? t('xxx_front') : t('xxx_back');
    }

    getCardTypeCopy() {
        const { t } = this.props;

        switch (this.props.cardType) {
            case 1:
                return t('xxx_id_card'); // passport
            case 2:
                return 'medical card';
            case 3:
                return t('idpal_national_id'); // new for id's licence
            case 31:
                return t('idpal_driving_licence'); // new for id's licence
            case 32:
                return t('idpal_resident_card'); // new for id's licence
            default:
                return t('xxx_id_card');
        }
    }

    openCamera(type) {
        this.props.setCardType(type);
        this.navigateCamera();
    }

    getBackImage(cardType) {
        switch (cardType) {
            case 4:
                return idCardBackJpg;
            case 31:
                return dlBackJpg;
            default:
                return idCardBackJpg;
        }
    }

    getFrontImage(cardType) {
        switch (cardType) {
            case 4:
                return idCardFrontJpg;
            case 31:
                return dlFrontJpg;
            default:
                return idCardFrontJpg;
        }
    }

    navigateCamera() {
        this.setState({
            navigation: {
                action: 'next',
                props: {
                    documentId: this.props.location.state.documentId,
                    isIdPalCamera:
                        isIdPalCameraEnabled(this.props.captureMethod) ||
                        this.props.bypassAcuant,
                },
            },
        });
    }

    render() {
        if (this.state.processing) {
            return <Processing />;
        }
        const { t } = this.props;

        // Out of retries, and we've already tried this side
        if (
            this.props.submissionAttempts.remaining <= 0 &&
            this.props.sidesLeft === 0 &&
            this.props.submissionAttempts[
                this.props.sidesLeft === 2 ? 'front' : 'back'
            ] > 0
        ) {
            return (
                <Fragment>
                    <Header />
                    <OutOfRecaptures
                        continue={() => {
                            history.go(2);
                        }}
                        message='idpal_out_of_attempts'
                    />
                    <Navigator
                        page={'document_capture'}
                        action={this.state.navigation.action}
                        propsToPass={this.state.navigation.props}
                    />
                </Fragment>
            );
        }

        return (
            <Fragment>
                <Header />

                <div className='o-site-wrap'>
                    <div className='u-generic-text  u-text-center'>
                        {t('xxx_upload_clear_picture')}{' '}
                        {this.getOrientationCopy()} {t('xxx_of_your')}{' '}
                        {this.getCardTypeCopy()}.
                    </div>

                    <div className='u-display-upload u-text-center'>
                        {this.props.sidesLeft === 2 && (
                            <img
                                alt='Illustration of an Id'
                                className={'capture'}
                                src={this.getFrontImage(this.props.cardType)}
                            />
                        )}
                        {this.props.sidesLeft === 1 && (
                            <img
                                alt='Illustration of an Id'
                                className={'capture'}
                                src={
                                    this.props.frontSubmitted
                                        ? this.getFrontImage(
                                              this.props.cardType
                                          )
                                        : this.getBackImage(this.props.cardType)
                                }
                            />
                        )}
                    </div>

                    <div className='o-entryBtns'>
                        {this.props.sidesLeft === 2 && (
                            <Fragment>
                                {process.env.REACT_APP_ID_PASSPORT_ENABLED ===
                                    'true' && (
                                    <CustomButton
                                        className='btn'
                                        handleClick={() => this.openCamera(1)}
                                        label='Capture ID/Passport'
                                        actionDataLabel={
                                            ACTION.openCameraPassportButton
                                        }
                                    />
                                )}
                                {process.env.REACT_APP_MEDICAL_CARD_ENABLED ===
                                    'true' && (
                                    <CustomButton
                                        className='btn'
                                        handleClick={() => this.openCamera(2)}
                                        label='Capture Medical Card'
                                    />
                                )}
                            </Fragment>
                        )}

                        {this.props.sidesLeft === 1 && (
                            <CustomButton
                                className='btn'
                                handleClick={() =>
                                    this.openCamera(this.props.cardType)
                                }
                                label={t('idpal_continue')}
                                actionDataLabel={ACTION.continueButton}
                            />
                        )}
                        {this.props.sidesLeft === 1 &&
                            this.props.cardType === 2 && (
                                <div
                                    className={'btn outline'}
                                    onClick={() => {
                                        this.props.history.push(
                                            '/results/medicard'
                                        );
                                    }}
                                >
                                    <p className={'buttonBdText'}>
                                        {t('xxxx_skip_step')}
                                    </p>
                                </div>
                            )}
                    </div>
                </div>

                <Navigator
                    page={'capture_photo'}
                    action={this.state.navigation.action}
                    propsToPass={this.state.navigation.props}
                />
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        bypassAcuant: state.config.bypassAcuant,
        orientation: state.idProperties.orientation,
        cardType: state.idProperties.cardType,
        sidesLeft: state.idProperties.sidesLeft,
        frontSubmitted: state.config.frontSubmitted,
        backSubmitted: state.config.backSubmitted,
        submissionAttempts: state.submissionState.submissionAttempts,
        captureMethod:
            state.submissionState.screens.document_upload.capture_method,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setCardType,
            setInstanceID,
            setCardOrientation,
            decrementSidesLeft,
            submitFrontID,
            submitBackID,
        },
        dispatch
    );
}

export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(CapturePhoto)
);
