/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

// Actions
import { sendSuccessMessage } from './actions/messageActions';

// Helpers
import { getCompanyName, isInIframe } from '../services/shared/helpers';

// Components
import Header from './Header';

// Services
import DataDogService from '../services/shared/datadogService';
import { areCookiesEnabled } from '../services/shared/helpers';
import selectStorage from '../services/shared/selectStorage';

// Images
import loadingBlueSvg from '../assets/img/loading-blue.svg';
import successSvg from '../assets/img/success.svg';
import { imageAlt } from '../config/accessabilityRules';

function successImage() {
    if (selectStorage(areCookiesEnabled()).getItem('brand') === 'pcs') {
        return <img alt={imageAlt.success} src={loadingBlueSvg} />;
    } else {
        return <img alt={imageAlt.success} src={successSvg} />;
    }
}

const SUCCESS_SCREEN_OPTIONS = {
    basic: 'basic',
    mobile: 'mobile',
    default: 'default',
};

class Success extends Component {
    constructor(props) {
        super(props);
        this.primaryFocusRef = React.createRef();
        this.state = {
            successScreen: 'default',
        };
    }

    componentDidMount() {
        this.setState({
            successScreen: this.getSuccessMessage(),
        });

        this.setSubmissionComplete();
        // prettier-ignore
        { DataDogService.log('Submission Completed'); } // eslint-disable-line
        document.dispatchEvent(new Event('freeze')); // Workaround to manually flush DataDog Logs
        this.clearUserPII();
        sendSuccessMessage('*');

        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }

        // Set document title
        const { t } = this.props;
        document.title = t('idpal_doc_title_success');
    }

    setSubmissionComplete() {
        selectStorage(areCookiesEnabled()).setItem('submission_complete', true);
    }

    clearUserPII() {
        selectStorage(areCookiesEnabled()).removeItem('uuid');
        selectStorage(areCookiesEnabled()).removeItem('sessionid');
        selectStorage(areCookiesEnabled()).removeItem('submissionid');
        selectStorage(areCookiesEnabled()).removeItem('region');
        selectStorage(areCookiesEnabled()).removeItem('disableheader');
        selectStorage(areCookiesEnabled()).removeItem(
            'passive_liveness_enabled'
        );
        selectStorage(areCookiesEnabled()).removeItem('target');
        selectStorage(areCookiesEnabled()).removeItem('handoff_session');
    }

    getSuccessMessage() {
        const isEmbeddedInIframe = isInIframe();
        const isHandOff = this.props.isHandoff;

        if (isEmbeddedInIframe && !isHandOff) {
            return SUCCESS_SCREEN_OPTIONS.basic;
        } else if (isHandOff) {
            return SUCCESS_SCREEN_OPTIONS.mobile;
        } else {
            return SUCCESS_SCREEN_OPTIONS.default;
        }
    }

    render() {
        const { t } = this.props;

        return (
            <Fragment>
                <Header />

                <div className='o-site-wrap'>
                    {this.state.successScreen ===
                        SUCCESS_SCREEN_OPTIONS.basic && (
                        <div>
                            <h1
                                className='u-generic-text  u-text-center'
                                ref={this.primaryFocusRef}
                                tabIndex={0}
                            >
                                {t('idpal_success')}
                            </h1>
                            <div className='u-success-logo u-text-center u-btm-buffer'>
                                {successImage()}
                            </div>
                        </div>
                    )}

                    {this.state.successScreen ===
                        SUCCESS_SCREEN_OPTIONS.mobile && (
                        <div>
                            <h1
                                className='large-text u-text-center'
                                ref={this.primaryFocusRef}
                                tabIndex={0}
                            >
                                {t('idpal_your_submission_is_complete', {
                                    company: getCompanyName(
                                        this.props.branding.name
                                    ),
                                })}
                            </h1>
                            <div className='u-success-logo u-text-center u-btm-buffer'>
                                {successImage()}
                            </div>
                            <div className='u-generic-text u-text-center'>
                                {t('idpal_you_may_close_window')}
                            </div>
                        </div>
                    )}

                    {this.state.successScreen ===
                        SUCCESS_SCREEN_OPTIONS.default && (
                        <div>
                            <h1
                                className='large-text u-text-center u-xs-btm-buffer'
                                ref={this.primaryFocusRef}
                                tabIndex={0}
                            >
                                {t('idpal_your_submission_is_complete', {
                                    company: getCompanyName(
                                        this.props.branding.name
                                    ),
                                })}
                            </h1>
                            <div className='u-success-logo u-text-center u-btm-buffer'>
                                {successImage()}
                            </div>
                            <div className='sub-text u-text-center success-message'>
                                <div>
                                    {t('idpal_if_you_have_any_queries')}
                                    <div className='success-message'>
                                        {
                                            this.props.branding
                                                .costumer_support_email
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        isHandoff: state.submissionState.isHandoff,
        branding: state.config.profile.data.company_branding,
    };
}

export default withTranslation('translation')(
    connect(mapStateToProps)(Success)
);
