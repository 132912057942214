/**
 * @prettier
 */

import React, { Component } from 'react';

class DriversLicenseFrontSVG extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { isLandscape } = this.props;

        return (
            <div className={isLandscape ? 'rotate-instruction' : ''}>
                <svg
                    alt={this.props.alt}
                    width='100%'
                    height='100%'
                    viewBox='0 0 277 393'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    aria-labelledby='drivingFront'
                    role='img'
                >
                    <title id='drivingFront'>{this.props.alt}</title>
                    <rect
                        opacity='0.446661'
                        width='277'
                        height='393'
                        fill='black'
                    />
                    <path
                        d='M230 26.9999H228V28.9999H230V26.9999ZM230 23.9999V21.9999H228V23.9999H230ZM249.941 23.9999H251.941V21.9999H249.941V23.9999ZM249.941 44.4814V46.4814H251.941V44.4814H249.941ZM246.941 44.4814H244.941V46.4814H246.941V44.4814ZM246.941 26.9999H248.941V24.9999H246.941V26.9999ZM230 28.9999H231.5V24.9999H230V28.9999ZM228 23.9999V26.9999H232V23.9999H228ZM231.5 21.9999H230V25.9999H231.5V21.9999ZM248.441 21.9999H231.5V25.9999H248.441V21.9999ZM249.941 21.9999H248.441V25.9999H249.941V21.9999ZM251.941 24.1467V23.9999H247.941V24.1467H251.941ZM251.941 25.6467V24.1467H247.941V25.6467H251.941ZM251.941 26.9999V25.6467H247.941V26.9999H251.941ZM251.941 42.9814V26.9999H247.941V42.9814H251.941ZM251.941 44.4814V42.9814H247.941V44.4814H251.941ZM246.941 46.4814H249.941V42.4814H246.941V46.4814ZM244.941 42.9814V44.4814H248.941V42.9814H244.941ZM244.941 26.9999V42.9814H248.941V26.9999H244.941ZM231.5 28.9999H246.941V24.9999H231.5V28.9999Z'
                        fill='white'
                    />
                    <path
                        d='M47.9412 26.9999H49.9412V28.9999H47.9412V26.9999ZM47.9412 23.9999V21.9999H49.9412V23.9999H47.9412ZM28 23.9999H26V21.9999H28V23.9999ZM28 44.4814V46.4814H26V44.4814H28ZM31 44.4814H33V46.4814H31V44.4814ZM31 26.9999H29V24.9999H31V26.9999ZM47.9412 28.9999H46.4412V24.9999H47.9412V28.9999ZM49.9412 23.9999V26.9999H45.9412V23.9999H49.9412ZM46.4412 21.9999H47.9412V25.9999H46.4412V21.9999ZM29.5 21.9999H46.4412V25.9999H29.5V21.9999ZM28 21.9999H29.5V25.9999H28V21.9999ZM26 24.1467V23.9999H30V24.1467H26ZM26 25.6467V24.1467H30V25.6467H26ZM26 26.9999V25.6467H30V26.9999H26ZM26 42.9814V26.9999H30V42.9814H26ZM26 44.4814V42.9814H30V44.4814H26ZM31 46.4814H28V42.4814H31V46.4814ZM33 42.9814V44.4814H29V42.9814H33ZM33 26.9999V42.9814H29V26.9999H33ZM46.4412 28.9999H31V24.9999H46.4412V28.9999Z'
                        fill='white'
                    />
                    <path
                        d='M47.9412 366.963H49.9412V364.963H47.9412V366.963ZM47.9412 369.963V371.963H49.9412V369.963H47.9412ZM28 369.963H26V371.963H28V369.963ZM28 366.963H30H28ZM28 350.981H26H28ZM28 349.481V347.481H26V349.481H28ZM31 349.481H33V347.481H31V349.481ZM31 350.981H29H31ZM31 366.963H29V368.963H31V366.963ZM47.9412 364.963H46.4412V368.963H47.9412V364.963ZM49.9412 369.963V366.963H45.9412V369.963H49.9412ZM46.4412 371.963H47.9412V367.963H46.4412V371.963ZM29.5 371.963H46.4412V367.963H29.5V371.963ZM28 371.963H29.5V367.963H28V371.963ZM26 369.816V369.963H30V369.816H26ZM26 368.316V369.816H30V368.316H26ZM26 366.963V368.316H30V366.963H26ZM26 350.981L26 366.963H30L30 350.981H26ZM26 349.481V350.981H30V349.481H26ZM31 347.481H28V351.481H31V347.481ZM33 350.981V349.481H29V350.981H33ZM33 366.963L33 350.981H29L29 366.963H33ZM46.4412 364.963H31V368.963H46.4412V364.963Z'
                        fill='white'
                    />
                    <path
                        d='M230 366.963H228V364.963H230V366.963ZM230 369.963V371.963H228V369.963H230ZM249.941 369.963H251.941V371.963H249.941V369.963ZM249.941 366.963H251.941H249.941ZM249.941 350.981H247.941H249.941ZM249.941 349.481V347.481H251.941V349.481H249.941ZM246.941 349.481H244.941V347.481H246.941V349.481ZM246.941 350.981H244.941H246.941ZM246.941 366.963H248.941V368.963H246.941V366.963ZM230 364.963H231.5V368.963H230V364.963ZM228 369.963V366.963H232V369.963H228ZM231.5 371.963H230V367.963H231.5V371.963ZM248.441 371.963H231.5V367.963H248.441V371.963ZM249.941 371.963H248.441V367.963H249.941V371.963ZM251.941 369.816V369.963H247.941V369.816H251.941ZM251.941 368.316V369.816H247.941V368.316H251.941ZM251.941 366.963V368.316H247.941V366.963H251.941ZM251.941 350.981V366.963H247.941V350.981H251.941ZM251.941 349.481V350.981H247.941V349.481H251.941ZM246.941 347.481H249.941V351.481H246.941V347.481ZM244.941 350.981V349.481H248.941V350.981H244.941ZM244.941 366.963V350.981H248.941V366.963H244.941ZM231.5 364.963H246.941V368.963H231.5V364.963Z'
                        fill='white'
                    />
                    <g clipPath='url(#clip0_6:2966)'>
                        <rect
                            x='225.47'
                            y='57.82'
                            width='278.32'
                            height='172.86'
                            rx='13.22'
                            transform='rotate(90 225.47 57.82)'
                            fill='url(#paint0_linear_6:2966)'
                        />
                        <path
                            d='M77.66 148.93C77.1997 148.898 76.8514 148.501 76.88 148.04V77.62C76.8514 77.1594 77.1997 76.7621 77.66 76.73H170.78C171.003 76.7429 171.212 76.844 171.36 77.011C171.508 77.178 171.584 77.3971 171.57 77.62V148.06C171.584 148.283 171.508 148.502 171.36 148.669C171.212 148.836 171.003 148.937 170.78 148.95L77.66 148.93Z'
                            fill='url(#paint1_linear_6:2966)'
                        />
                        <path
                            d='M103.7 126.3C105.717 126.155 107.743 126.155 109.76 126.3C110.701 127.102 111.731 127.793 112.83 128.36C115.227 129.481 117.757 130.291 120.36 130.77C120.538 131.49 120.9 132.152 121.41 132.69C122.62 134.1 128.76 135.34 131.41 135.69C132.058 135.84 132.74 135.719 133.297 135.355C133.854 134.991 134.238 134.414 134.36 133.76C138.27 134.48 144.85 135.2 149.55 133.01C156.64 129.72 159.17 124.41 157.4 121.12V121.12C158.172 119.9 158.699 118.542 158.95 117.12C160.47 110.79 160.08 104.55 155.27 98.47C151.48 93.69 148.49 92.47 141.65 92.75C139.384 92.9559 137.135 93.3201 134.92 93.84C134.932 93.1449 134.619 92.4838 134.074 92.0524C133.529 91.6209 132.814 91.4684 132.14 91.64C129.51 92.05 122.67 93.42 121.46 94.84C120.925 95.395 120.555 96.0873 120.39 96.84C118.13 97.2273 115.915 97.8438 113.78 98.68C112.393 99.321 111.089 100.13 109.9 101.09C107.853 101.24 105.797 101.24 103.75 101.09L99.49 97.09L91.87 76.64H77.71C77.2497 76.6721 76.9014 77.0695 76.93 77.53V147.97C76.9014 148.431 77.2497 148.828 77.71 148.86H92.41L99.05 130.25L103.7 126.3Z'
                            fill='black'
                        />
                        <path
                            d='M152.3 167.24H146.47V167.06L152.32 163.16V163.16H146.83V164.16H146.6V162.05H146.83V162.93H152.3V161.99H152.52V164.25L148.37 167.01V167.01H152.3V166.01H152.52V168.11H152.3V167.24Z'
                            fill='#5B5B5B'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M149.05 171.16H148.6C148.53 169.68 148.11 168.94 147.36 168.92C147.125 168.917 146.901 169.019 146.75 169.2C146.578 169.403 146.489 169.664 146.5 169.93C146.522 170.426 146.788 170.879 147.21 171.14C146.74 171.22 146.5 171.46 146.5 171.87C146.486 172.056 146.547 172.239 146.669 172.38C146.791 172.521 146.964 172.607 147.15 172.62V172.48C147 172.38 146.93 172.28 146.93 172.17C146.928 172.107 146.963 172.048 147.02 172.02C147.168 171.988 147.322 171.988 147.47 172.02H149.09C149.283 172.029 149.475 172.006 149.66 171.95C149.816 171.895 149.94 171.774 150 171.62C150.108 171.343 150.156 171.047 150.14 170.75C150.16 170.352 150.077 169.956 149.9 169.6C149.794 169.365 149.567 169.208 149.31 169.19C149.202 169.185 149.097 169.225 149.02 169.3C148.937 169.377 148.893 169.487 148.9 169.6C148.899 169.694 148.931 169.786 148.99 169.86C149.06 169.941 149.163 169.985 149.27 169.98C149.348 169.983 149.425 169.962 149.49 169.92C149.543 169.884 149.606 169.863 149.67 169.86C149.85 169.86 149.94 170.1 149.94 170.58C149.94 170.84 149.88 171.01 149.77 171.07C149.538 171.147 149.294 171.177 149.05 171.16ZM148.39 171.16H147.92C147.754 171.163 147.589 171.139 147.43 171.09C147.294 171.024 147.174 170.929 147.08 170.81C146.967 170.68 146.907 170.512 146.91 170.34C146.907 170.202 146.965 170.07 147.07 169.98C147.194 169.865 147.361 169.807 147.53 169.82C147.765 169.809 147.993 169.904 148.15 170.08C148.33 170.41 148.413 170.785 148.39 171.16Z'
                            fill='#5B5B5B'
                        />
                        <path
                            d='M146.84 173.86H149.84V173.24H150.06V173.62C150.054 173.984 150.074 174.349 150.12 174.71H149.3V174.71C149.83 174.885 150.178 175.392 150.15 175.95C150.196 176.496 149.795 176.977 149.25 177.03V177.03C149.798 177.207 150.165 177.724 150.15 178.3C150.157 178.539 150.087 178.774 149.95 178.97C149.841 179.134 149.678 179.254 149.49 179.31C149.206 179.359 148.918 179.379 148.63 179.37H146.85V179.98H146.62V177.98H146.85V178.56H149.03C149.268 178.57 149.507 178.55 149.74 178.5C149.87 178.5 149.93 178.36 149.93 178.18C149.909 177.89 149.763 177.624 149.53 177.45C149.081 177.147 148.539 177.012 148 177.07H146.85V177.62H146.62V175.62H146.85V176.19H148.56C148.955 176.213 149.351 176.19 149.74 176.12C149.87 176.07 149.93 175.96 149.93 175.77C149.926 175.514 149.804 175.274 149.6 175.12C149.418 174.953 149.199 174.833 148.96 174.77C148.629 174.717 148.295 174.694 147.96 174.7H146.85V175.27H146.62V173.27H146.85L146.84 173.86Z'
                            fill='#5B5B5B'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M148.61 181.62V183.82C149.033 183.816 149.439 183.648 149.74 183.35C150.002 183.082 150.153 182.725 150.16 182.35C150.163 181.896 149.97 181.462 149.63 181.16C148.893 180.46 147.737 180.46 147 181.16C146.661 181.48 146.47 181.925 146.47 182.39C146.469 182.721 146.582 183.042 146.79 183.3C147.046 183.598 147.401 183.793 147.79 183.85V183.66C147.164 183.562 146.702 183.024 146.7 182.39C146.674 182.14 146.79 181.897 147 181.76C147.523 181.612 148.069 181.564 148.61 181.62ZM148.81 182.87V181.59L149.36 181.55C149.532 181.54 149.7 181.606 149.82 181.73C149.942 181.867 150.003 182.047 149.99 182.23C149.99 182.65 149.76 182.87 149.29 182.87C149.13 182.881 148.97 182.881 148.81 182.87Z'
                            fill='#5B5B5B'
                        />
                        <path
                            d='M141.68 162.37V163.44L138.35 165.28L137.1 165.89V165.89C137.7 165.84 138.45 165.75 139.1 165.75H141.67V166.75H135.76V165.72L139.08 163.88L140.34 163.27V163.27C139.72 163.27 139 163.41 138.34 163.41H135.76V162.41L141.68 162.37Z'
                            fill='#1E1E1E'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M137.37 169.04V171.04L135.77 171.49V172.59L141.68 170.67V169.44L135.77 167.52V168.58L137.37 169.04ZM138.91 170.6L138.19 170.81V169.34L138.91 169.55C139.58 169.74 140.25 169.91 140.91 170.07C140.21 170.24 139.54 170.41 138.91 170.6Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M141.68 173.34V174.49L138.77 175.56L137.65 175.95V175.95L138.77 176.33L141.68 177.33V178.62H135.77V177.62H138.47C139.02 177.62 139.81 177.7 140.35 177.76V177.76L139 177.28L136.32 176.28V175.8L139 174.8L140.35 174.33V174.33C139.81 174.33 139.02 174.46 138.47 174.46H135.77V173.46L141.68 173.34Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M141.68 180.07V183.62H140.79V181.12H139.3V183.24H138.42V181.12H136.68V183.72H135.79V180.07H141.68Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M137.24 186.99C136.838 187.405 136.6 187.953 136.57 188.53C136.57 189.2 136.87 189.53 137.33 189.53C137.79 189.53 137.97 189.15 138.2 188.61L138.55 187.8C138.769 187.111 139.398 186.635 140.12 186.61C140.611 186.642 141.068 186.875 141.383 187.252C141.699 187.63 141.846 188.121 141.79 188.61C141.785 189.288 141.516 189.937 141.04 190.42L140.37 189.89C140.683 189.536 140.86 189.082 140.87 188.61C140.87 188.05 140.61 187.68 140.18 187.68C139.75 187.68 139.55 188.13 139.35 188.62L139.01 189.42C138.804 190.131 138.15 190.617 137.41 190.61C136.89 190.578 136.406 190.332 136.074 189.93C135.743 189.528 135.593 189.007 135.66 188.49C135.661 187.694 135.973 186.929 136.53 186.36L137.24 186.99Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M141.68 191.68V192.73H138.3C137.02 192.73 136.59 193.21 136.59 193.92C136.59 194.63 137.02 195.15 138.3 195.15H141.7V196.15H138.38C136.44 196.15 135.68 195.29 135.68 193.92C135.68 192.55 136.44 191.68 138.38 191.68H141.68Z'
                            fill='#1E1E1E'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M138.08 199.62L135.77 200.85V202.12L138.24 200.71C138.462 201.446 139.163 201.931 139.93 201.88C141.24 201.88 141.68 200.94 141.68 199.71V197.71H135.77V198.71H138.08V199.62ZM138.91 199.52V198.67H140.82V199.52C140.82 200.32 140.56 200.76 139.91 200.76C139.26 200.76 138.91 200.32 138.91 199.52Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M141.68 203.04V204.11L138.35 205.96L137.1 206.56V206.56C137.7 206.56 138.45 206.42 139.1 206.42H141.67V207.42H135.76V206.34L139.08 204.5L140.34 203.89V203.89C139.72 203.89 139 204.02 138.34 204.02H135.76V203.02L141.68 203.04Z'
                            fill='#1E1E1E'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M137.37 209.71V211.71L135.77 212.15V213.26L141.68 211.33V210.1L135.77 208.18V209.25L137.37 209.71ZM138.91 211.27L138.19 211.48V210.01L138.91 210.22C139.58 210.41 140.25 210.58 140.91 210.74C140.21 210.91 139.54 211.08 138.91 211.27Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M141.68 214.01V215.17L138.77 216.23L137.65 216.62V216.62L138.77 217L141.68 218V219.16H135.77V218.16H138.47C139.02 218.16 139.81 218.24 140.35 218.29V218.29L139 217.82L136.32 216.82V216.33L139 215.33L140.35 214.86V214.86C139.81 214.92 139.02 215 138.47 215H135.77V214L141.68 214.01Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M141.68 220.74V224.3H140.79V221.79H139.3V223.91H138.42V221.79H136.68V224.39H135.79V220.74L141.68 220.74Z'
                            fill='#1E1E1E'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M120.93 163.52V165.4L119.3 166.02V165.02H119.08V167.84H119.3V167.05L125.13 164.98V164.84L119.3 162.69V161.87H119.09V164.01H119.31V162.92L120.93 163.52ZM121.16 165.32V163.61L123.53 164.49L121.16 165.32Z'
                            fill='#5B5B5B'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M119.3 172H125.07C125.022 171.696 124.995 171.388 124.99 171.08V170.28H124.77V171.08H122.03C122.42 170.901 122.667 170.509 122.66 170.08C122.659 169.62 122.45 169.186 122.09 168.9C121.735 168.569 121.266 168.386 120.78 168.39C120.315 168.388 119.866 168.559 119.52 168.87C119.178 169.133 118.975 169.538 118.97 169.97C118.965 170.474 119.277 170.926 119.75 171.1H119.02C119.059 171.432 119.079 171.766 119.08 172.1V172.6H119.3V172ZM122.11 169.5C122.358 169.588 122.509 169.84 122.47 170.1C122.472 170.405 122.327 170.692 122.08 170.87C121.783 171.062 121.433 171.156 121.08 171.14C120.728 171.15 120.376 171.123 120.03 171.06C119.808 170.99 119.61 170.859 119.46 170.68C119.294 170.505 119.204 170.271 119.21 170.03C119.196 169.833 119.287 169.643 119.45 169.53C119.835 169.397 120.244 169.346 120.65 169.38C121.14 169.356 121.631 169.397 122.11 169.5Z'
                            fill='#5B5B5B'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M119.3 176.8H125.07C125.022 176.496 124.995 176.188 124.99 175.88V175.08H124.77V175.88H122.03C122.42 175.701 122.667 175.309 122.66 174.88C122.655 174.421 122.446 173.989 122.09 173.7C121.731 173.375 121.264 173.197 120.78 173.2C120.315 173.198 119.866 173.369 119.52 173.68C119.178 173.939 118.975 174.341 118.97 174.77C118.958 175.276 119.273 175.732 119.75 175.9H119.02C119.059 176.232 119.079 176.566 119.08 176.9V177.39H119.3V176.8ZM122.11 174.3C122.358 174.388 122.509 174.64 122.47 174.9C122.473 175.202 122.327 175.486 122.08 175.66C121.786 175.86 121.435 175.958 121.08 175.94C120.728 175.953 120.375 175.923 120.03 175.85C119.808 175.784 119.61 175.656 119.46 175.48C119.301 175.3 119.213 175.07 119.21 174.83C119.194 174.632 119.286 174.441 119.45 174.33C119.834 174.192 120.243 174.137 120.65 174.17C121.14 174.148 121.631 174.192 122.11 174.3Z'
                            fill='#5B5B5B'
                        />
                        <path
                            d='M119.3 178.49H122.3V177.78H122.53V178.5C122.521 178.784 122.538 179.069 122.58 179.35H121.75V179.35C122.3 179.55 122.58 179.86 122.58 180.26C122.591 180.413 122.538 180.563 122.434 180.675C122.329 180.787 122.183 180.85 122.03 180.85C121.904 180.852 121.782 180.805 121.69 180.72C121.601 180.63 121.554 180.507 121.56 180.38C121.547 180.276 121.583 180.171 121.657 180.097C121.731 180.023 121.836 179.987 121.94 180H122.13C122.2 180 122.23 180 122.23 179.91C122.23 179.82 122.1 179.69 121.84 179.54C121.465 179.365 121.053 179.286 120.64 179.31H119.3V180.04H119.08V177.74H119.3V178.49Z'
                            fill='#5B5B5B'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M121.09 182.32V184.56C121.517 184.553 121.923 184.377 122.22 184.07C122.485 183.804 122.636 183.445 122.64 183.07C122.636 182.614 122.444 182.18 122.11 181.87C121.366 181.179 120.214 181.179 119.47 181.87C119.134 182.191 118.946 182.636 118.95 183.1C118.947 183.43 119.056 183.751 119.26 184.01C119.516 184.308 119.871 184.503 120.26 184.56V184.37C119.638 184.267 119.181 183.73 119.18 183.1C119.154 182.85 119.27 182.607 119.48 182.47C120.001 182.309 120.549 182.258 121.09 182.32ZM121.3 183.62V182.32L121.86 182.3C122.034 182.292 122.202 182.362 122.32 182.49C122.438 182.625 122.499 182.801 122.49 182.98C122.49 183.41 122.25 183.62 121.78 183.62H121.3Z'
                            fill='#5B5B5B'
                        />
                        <path
                            d='M122.59 187.62H121.59V187.44C122.087 187.37 122.455 186.942 122.45 186.44C122.457 186.235 122.393 186.034 122.27 185.87C122.176 185.736 122.024 185.655 121.86 185.65C121.736 185.646 121.619 185.707 121.55 185.81C121.44 186.049 121.376 186.307 121.36 186.57C121.247 187.45 120.847 187.89 120.16 187.89C119.84 187.895 119.53 187.773 119.3 187.55C119.074 187.326 118.951 187.018 118.96 186.7C118.96 186.326 119.107 185.966 119.37 185.7L119.1 185.39V185.24H120.4V185.39C119.57 185.65 119.16 186.07 119.16 186.67C119.161 186.891 119.235 187.105 119.37 187.28C119.484 187.451 119.675 187.555 119.88 187.56C120.022 187.57 120.16 187.51 120.25 187.4C120.376 187.115 120.451 186.811 120.47 186.5C120.6 185.68 120.96 185.26 121.58 185.26C121.871 185.257 122.148 185.382 122.34 185.6C122.543 185.82 122.654 186.11 122.65 186.41C122.655 186.727 122.532 187.033 122.31 187.26C122.388 187.331 122.476 187.392 122.57 187.44L122.59 187.62Z'
                            fill='#5B5B5B'
                        />
                        <path
                            d='M122.59 191H121.59V190.82C122.087 190.75 122.455 190.322 122.45 189.82C122.458 189.615 122.395 189.413 122.27 189.25C122.176 189.116 122.024 189.035 121.86 189.03C121.736 189.026 121.619 189.087 121.55 189.19C121.439 189.429 121.374 189.687 121.36 189.95C121.247 190.83 120.847 191.27 120.16 191.27C119.84 191.275 119.53 191.153 119.3 190.93C119.075 190.705 118.952 190.398 118.96 190.08C118.96 189.706 119.107 189.346 119.37 189.08L119.1 188.77V188.62H120.4V188.77C119.57 189.02 119.16 189.45 119.16 190.05C119.161 190.271 119.235 190.485 119.37 190.66C119.484 190.831 119.675 190.935 119.88 190.94C120.022 190.95 120.16 190.89 120.25 190.78C120.376 190.495 120.451 190.191 120.47 189.88C120.6 189.06 120.96 188.64 121.58 188.64C121.871 188.637 122.148 188.762 122.34 188.98C122.543 189.2 122.654 189.49 122.65 189.79C122.655 190.107 122.532 190.413 122.31 190.64C122.388 190.711 122.476 190.772 122.57 190.82L122.59 191Z'
                            fill='#5B5B5B'
                        />
                        <path
                            d='M109.09 162.29V163.62H112.87V162.56H113.52C113.593 163.029 113.752 163.48 113.99 163.89V164.62H109.09V165.75H108.24V162.29H109.09Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M108.85 166.62C110.39 168.22 111.4 169.22 112.28 169.22C112.555 169.257 112.831 169.163 113.027 168.967C113.223 168.771 113.317 168.495 113.28 168.22C113.237 167.772 113.004 167.364 112.64 167.1L113.22 166.52C113.781 166.966 114.108 167.643 114.11 168.36C114.148 168.844 113.976 169.32 113.636 169.667C113.297 170.014 112.824 170.198 112.34 170.17C111.34 170.17 110.28 169.17 109.08 168.08C109.08 168.38 109.14 168.76 109.14 169.08V170.49H108.25V166.62H108.85Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M109.62 171.62C109.224 171.967 108.992 172.464 108.98 172.99C108.98 173.61 109.31 174.04 109.85 174.04C110.39 174.04 110.85 173.66 110.85 172.35H111.61C111.61 173.48 111.99 173.85 112.53 173.85C113.07 173.85 113.29 173.53 113.29 173C113.272 172.56 113.077 172.145 112.75 171.85L113.39 171.31C113.847 171.771 114.109 172.391 114.12 173.04C114.12 174.14 113.57 174.9 112.6 174.9C111.987 174.897 111.449 174.49 111.28 173.9V173.9C111.155 174.587 110.558 175.087 109.86 175.09C109.368 175.06 108.909 174.829 108.593 174.451C108.277 174.072 108.131 173.58 108.19 173.09C108.16 172.35 108.46 171.636 109.01 171.14L109.62 171.62Z'
                            fill='#1E1E1E'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M109.84 178.87V180.87L108.24 181.32V182.42L114.15 180.5V179.27L108.24 177.35V178.41L109.84 178.87ZM111.39 180.43L110.66 180.64V179.17L111.39 179.38C112.01 179.57 112.68 179.74 113.34 179.9C113.223 179.93 113.106 179.961 112.988 179.992C112.448 180.132 111.899 180.274 111.39 180.43Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M114.15 183.17V184.24L110.83 186.09L109.57 186.69V186.69C110.18 186.64 110.93 186.55 111.57 186.55H114.13V187.55H108.22V186.52L111.55 184.68L112.8 184.07V184.07C112.18 184.07 111.47 184.2 110.8 184.2H108.21V183.2L114.15 183.17Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M110.44 190.08L114.15 188.31V189.43L112.64 190.07C112.2 190.24 111.79 190.41 111.34 190.6V190.6C111.79 190.79 112.2 190.97 112.64 191.14L114.15 191.79V192.88L110.44 191.1H108.24V190.1L110.44 190.08Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M114.15 193.04V194.11L111.15 194.63C110.52 194.73 109.89 194.82 109.26 194.92V194.92C109.89 195.05 110.53 195.17 111.15 195.31L114.15 196.03V196.94L111.15 197.67C110.54 197.8 109.89 197.93 109.26 198.07V198.07C109.89 198.16 110.53 198.26 111.15 198.35L114.15 198.87V199.87L108.24 198.74V197.44L111.35 196.71C111.81 196.61 112.26 196.52 112.71 196.45V196.45C112.26 196.37 111.81 196.28 111.35 196.18L108.24 195.46V194.19L114.15 193.04Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M114.15 200.85V204.41H113.3V201.9H111.79V204.02H110.9V201.9H109.16V204.5H108.27V200.85H114.15Z'
                            fill='#1E1E1E'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M111.95 207.24C111.95 207.84 111.55 208.04 111.08 208.04C110.88 206.23 110.38 205.43 109.44 205.45C109.087 205.436 108.743 205.57 108.494 205.821C108.245 206.072 108.113 206.417 108.13 206.77C108.162 207.286 108.374 207.775 108.73 208.15L108.24 208.23V209.08H110.87C112.11 209.08 112.79 208.54 112.79 207.43C112.772 206.781 112.571 206.15 112.21 205.61L111.51 205.99C111.769 206.358 111.921 206.791 111.95 207.24ZM108.95 207.049C108.95 207.046 108.95 207.043 108.95 207.04V207.08C108.95 207.07 108.95 207.059 108.95 207.049ZM109.44 208.04C109.144 207.794 108.966 207.434 108.95 207.049C108.958 206.696 109.102 206.45 109.53 206.45C109.97 206.45 110.29 206.84 110.44 208.04H109.44Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M109.72 212.5C109.312 212.912 109.073 213.461 109.05 214.04C109.05 214.7 109.35 215.04 109.81 215.04C110.27 215.04 110.45 214.66 110.67 214.12L111.03 213.31C111.244 212.618 111.876 212.139 112.6 212.12C113.089 212.155 113.543 212.388 113.857 212.766C114.17 213.143 114.316 213.633 114.26 214.12C114.255 214.798 113.986 215.447 113.51 215.93L112.84 215.4C113.163 215.051 113.344 214.595 113.35 214.12C113.35 213.55 113.08 213.18 112.65 213.18C112.22 213.18 112.02 213.64 111.82 214.12L111.49 214.93C111.281 215.638 110.629 216.124 109.89 216.12C109.368 216.091 108.882 215.846 108.548 215.444C108.214 215.042 108.062 214.518 108.13 214C108.131 213.204 108.443 212.439 109 211.87L109.72 212.5Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M113.3 218.23V216.52H114.18V220.98H113.3V219.28H108.3V218.28L113.3 218.23Z'
                            fill='#1E1E1E'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M110.56 223.92L108.24 225.2V226.37L110.71 224.96C110.935 225.694 111.634 226.178 112.4 226.13C113.72 226.13 114.15 225.2 114.15 223.97V221.97H108.24V223.02H110.56V223.92ZM111.39 223.86V223.02H113.3V223.86C113.3 224.66 113.05 225.1 112.39 225.1C111.73 225.1 111.39 224.66 111.39 223.86Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M114.15 227.38V230.94H113.3V228.43H111.79V230.55H110.9V228.43H109.16V231.03H108.27V227.38L114.15 227.38Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M114.15 232.24V235.8H113.3V233.29H111.79V235.41H110.9V233.29H109.16V235.89H108.27V232.24L114.15 232.24Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M113.3 238.3V236.62H114.18V241.08H113.3V239.38H108.3V238.33L113.3 238.3Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M103.42 164.79C103.406 165.428 103.136 166.034 102.67 166.47L102.02 165.88C102.322 165.602 102.496 165.211 102.5 164.8C102.5 163.8 101.69 163.14 100.36 163.14C99.03 163.14 98.2 163.77 98.2 164.76C98.2003 165.241 98.4169 165.696 98.79 166L98.15 166.59C97.6013 166.133 97.2858 165.454 97.29 164.74C97.29 163.23 98.38 162.06 100.29 162.06C101.077 161.96 101.869 162.201 102.467 162.723C103.065 163.244 103.412 163.997 103.42 164.79H103.42Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M103.3 167.62V168.62H97.39V167.62H103.3Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M102.42 171.31V169.62H103.3V174.08H102.41V172.37H97.41V171.32L102.42 171.31Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M99.59 175.89L103.3 174.12V175.24L101.79 175.89C101.35 176.06 100.94 176.22 100.49 176.41V176.41C100.94 176.6 101.35 176.78 101.79 176.95L103.3 177.62V178.71L99.58 176.93H97.39V175.93L99.59 175.89Z'
                            fill='#1E1E1E'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M124.77 271.2H119.3V270.27H119.12V273.05C119.103 273.576 119.191 274.099 119.38 274.59C119.59 275.058 119.939 275.449 120.38 275.71C120.884 276.032 121.472 276.199 122.07 276.19C122.653 276.197 123.227 276.045 123.73 275.75C124.18 275.509 124.533 275.121 124.73 274.65C124.917 274.145 125.006 273.609 124.99 273.07V270.27H124.77V271.2ZM119.3 272.2H124.77V273C124.782 273.358 124.738 273.716 124.64 274.06C124.516 274.369 124.275 274.617 123.97 274.75C123.447 274.963 122.884 275.058 122.32 275.03C121.822 275.037 121.324 275.007 120.83 274.94C120.495 274.898 120.174 274.782 119.89 274.6C119.664 274.449 119.495 274.228 119.41 273.97C119.329 273.643 119.292 273.307 119.3 272.97V272.2Z'
                            fill='#5B5B5B'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M121.52 279.41H121.07C120.997 277.93 120.58 277.19 119.82 277.19C119.587 277.186 119.363 277.284 119.21 277.46C119.035 277.665 118.945 277.93 118.96 278.2C118.978 278.695 119.24 279.148 119.66 279.41C119.19 279.49 118.96 279.73 118.96 280.14C118.943 280.325 119.002 280.508 119.122 280.65C119.243 280.791 119.415 280.877 119.6 280.89V280.78C119.46 280.68 119.38 280.58 119.38 280.47C119.378 280.407 119.413 280.348 119.47 280.32C119.615 280.271 119.768 280.254 119.92 280.27H121.54C121.736 280.274 121.931 280.25 122.12 280.2C122.272 280.138 122.394 280.02 122.46 279.87C122.563 279.592 122.607 279.296 122.59 279C122.61 278.602 122.527 278.206 122.35 277.85C122.244 277.615 122.017 277.458 121.76 277.44C121.654 277.437 121.551 277.472 121.47 277.54C121.326 277.703 121.326 277.947 121.47 278.11C121.535 278.192 121.636 278.236 121.74 278.23C121.821 278.231 121.9 278.211 121.97 278.17C122.02 278.134 122.079 278.113 122.14 278.11C122.32 278.11 122.41 278.35 122.41 278.83C122.41 279.09 122.36 279.26 122.24 279.32C122.009 279.399 121.764 279.429 121.52 279.41ZM120.87 279.41H120.39C120.224 279.413 120.059 279.389 119.9 279.34C119.764 279.274 119.644 279.179 119.55 279.06C119.441 278.927 119.381 278.761 119.38 278.59C119.377 278.452 119.435 278.32 119.54 278.23C119.664 278.115 119.831 278.057 120 278.07C120.236 278.056 120.465 278.152 120.62 278.33C120.803 278.659 120.89 279.034 120.87 279.41Z'
                            fill='#5B5B5B'
                        />
                        <path
                            d='M123.56 282.94H122.56V283.94H122.3V282.94H120.12C119.898 282.93 119.676 282.95 119.46 283C119.34 283 119.28 283.16 119.28 283.34C119.283 283.488 119.332 283.631 119.42 283.75C119.533 283.892 119.687 283.996 119.86 284.05V284.24C119.298 284.115 118.924 283.581 119 283.01C118.986 282.785 119.042 282.562 119.16 282.37C119.254 282.241 119.393 282.152 119.55 282.12C119.756 282.101 119.964 282.101 120.17 282.12H122.3V281.33H122.53V282.12H123.46C123.471 282.396 123.504 282.67 123.56 282.94Z'
                            fill='#5B5B5B'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M121.09 285.79V288.02C121.517 288.007 121.923 287.828 122.22 287.52C122.482 287.252 122.633 286.895 122.64 286.52C122.638 286.067 122.445 285.635 122.11 285.33C121.364 284.644 120.216 284.644 119.47 285.33C119.133 285.654 118.944 286.103 118.95 286.57C118.948 286.897 119.057 287.214 119.26 287.47C119.513 287.772 119.87 287.968 120.26 288.02V287.83C119.64 287.732 119.182 287.198 119.18 286.57C119.149 286.32 119.266 286.074 119.48 285.94C120.001 285.779 120.549 285.728 121.09 285.79ZM121.28 287.07V285.79L121.84 285.75C122.012 285.744 122.178 285.809 122.3 285.93C122.418 286.069 122.479 286.248 122.47 286.43C122.47 286.86 122.23 287.07 121.76 287.07C121.6 287.081 121.44 287.081 121.28 287.07Z'
                            fill='#5B5B5B'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M122.13 291.46C121.786 291.115 121.317 290.923 120.83 290.93C120.337 290.921 119.861 291.113 119.51 291.46C119.168 291.794 118.977 292.252 118.98 292.73C118.973 293.218 119.173 293.687 119.53 294.02C119.88 294.383 120.366 294.582 120.87 294.57C121.353 294.586 121.819 294.393 122.15 294.04C122.489 293.701 122.676 293.239 122.67 292.76C122.681 292.27 122.485 291.798 122.13 291.46ZM121.9 293.52C121.546 293.572 121.188 293.592 120.83 293.58V293.62C120.468 293.619 120.106 293.585 119.75 293.52C119.58 293.495 119.428 293.401 119.33 293.26C119.225 293.111 119.173 292.932 119.18 292.75C119.174 292.568 119.227 292.39 119.33 292.24C119.43 292.106 119.576 292.013 119.74 291.98C120.098 291.928 120.459 291.908 120.82 291.92C121.177 291.911 121.535 291.928 121.89 291.97C122.06 291.995 122.212 292.089 122.31 292.23C122.419 292.381 122.475 292.564 122.47 292.75C122.481 292.94 122.425 293.128 122.31 293.28C122.208 293.408 122.062 293.494 121.9 293.52Z'
                            fill='#5B5B5B'
                        />
                        <path
                            d='M119.3 295.92H122.3V295.19H122.53V295.92C123.18 295.866 123.829 296.036 124.37 296.4C124.782 296.68 125.032 297.142 125.04 297.64C125.04 297.856 124.97 298.067 124.84 298.24C124.728 298.394 124.55 298.486 124.36 298.49C124.239 298.493 124.121 298.45 124.03 298.37C123.947 298.288 123.901 298.176 123.9 298.06C123.898 297.959 123.938 297.861 124.01 297.79C124.083 297.713 124.184 297.669 124.29 297.67C124.476 297.679 124.642 297.791 124.72 297.96C124.809 297.854 124.852 297.718 124.84 297.58C124.841 297.411 124.788 297.247 124.69 297.11C124.587 296.958 124.423 296.859 124.24 296.84C123.908 296.8 123.574 296.783 123.24 296.79H122.5V297.62H122.3V296.8H119.3V297.54H119.08V295.27H119.3V295.92Z'
                            fill='#5B5B5B'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M121.97 303.22H125.07C125.016 302.94 124.99 302.655 124.99 302.37V301.75H124.77V302.37H119.3V301.75H119.09V302.39C119.096 302.668 119.076 302.946 119.03 303.22H119.78C119.303 303.392 118.984 303.843 118.98 304.35C118.987 304.783 119.198 305.187 119.55 305.44C119.91 305.748 120.367 305.918 120.84 305.92C121.311 305.923 121.767 305.752 122.12 305.44C122.462 305.164 122.664 304.75 122.67 304.31C122.689 303.836 122.409 303.4 121.97 303.22ZM119.55 304.85C119.32 304.79 119.21 304.61 119.21 304.32C119.224 304.012 119.379 303.728 119.63 303.55C119.954 303.312 120.349 303.192 120.75 303.21C121.087 303.196 121.424 303.233 121.75 303.32C121.949 303.393 122.122 303.522 122.25 303.69C122.397 303.859 122.475 304.076 122.47 304.3C122.47 304.6 122.36 304.78 122.16 304.85C121.735 304.933 121.302 304.967 120.87 304.95C120.428 304.97 119.984 304.936 119.55 304.85Z'
                            fill='#5B5B5B'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M124.66 307.35C124.572 307.257 124.448 307.206 124.32 307.21H124.3C124.178 307.208 124.061 307.259 123.98 307.35C123.89 307.44 123.84 307.563 123.84 307.69C123.836 307.818 123.887 307.942 123.98 308.03C124.069 308.122 124.192 308.173 124.32 308.17C124.447 308.17 124.57 308.12 124.66 308.03C124.75 307.94 124.801 307.818 124.801 307.69C124.801 307.562 124.75 307.44 124.66 307.35ZM119.3 308.09H122.62C122.569 307.813 122.539 307.532 122.53 307.25V306.62H122.3V307.24H119.3V306.62H119.08V308.67H119.3V308.09Z'
                            fill='#5B5B5B'
                        />
                        <path
                            d='M119.3 309.71H122.3V309H122.53V309.72C122.521 310.004 122.538 310.289 122.58 310.57H121.75V310.57C122.3 310.77 122.58 311.08 122.58 311.48C122.591 311.633 122.538 311.783 122.434 311.895C122.329 312.007 122.183 312.07 122.03 312.07C121.904 312.072 121.782 312.025 121.69 311.94C121.601 311.85 121.554 311.727 121.56 311.6C121.547 311.496 121.583 311.391 121.657 311.317C121.731 311.243 121.836 311.207 121.94 311.22H122.13C122.2 311.22 122.23 311.22 122.23 311.13C122.23 311.04 122.1 310.91 121.84 310.75C121.463 310.584 121.052 310.509 120.64 310.53H119.3V311.26H119.08V308.96H119.3V309.71Z'
                            fill='#5B5B5B'
                        />
                        <path
                            d='M123.56 314.32H122.56V315.37H122.3V314.32H120.12C119.898 314.309 119.676 314.33 119.46 314.38C119.34 314.43 119.28 314.54 119.28 314.73C119.284 314.875 119.333 315.015 119.42 315.13C119.533 315.272 119.687 315.376 119.86 315.43V315.62C119.501 315.487 119.217 315.204 119.082 314.845C118.948 314.486 118.976 314.086 119.16 313.75C119.255 313.623 119.394 313.537 119.55 313.51C119.754 313.469 119.962 313.452 120.17 313.46H122.3V312.67H122.53V313.46H123.46C123.468 313.749 123.501 314.037 123.56 314.32V314.32Z'
                            fill='#5B5B5B'
                        />
                        <path
                            d='M119.3 316.8H124.77V316.12H124.99V316.78C124.985 317.075 125.008 317.37 125.06 317.66H121.77V317.66C122.267 317.873 122.592 318.359 122.6 318.9C122.6 319.117 122.545 319.33 122.44 319.52C122.347 319.716 122.171 319.859 121.96 319.91C121.632 319.984 121.296 320.014 120.96 320H119.3V320.62H119.08V318.62H119.3V319.18H121.06C121.393 319.201 121.727 319.201 122.06 319.18C122.27 319.18 122.37 319.03 122.37 318.79C122.369 318.533 122.247 318.292 122.04 318.14C121.845 317.978 121.616 317.862 121.37 317.8C121.02 317.706 120.662 317.646 120.3 317.62H119.3V318.19H119.08V316.14H119.3V316.8Z'
                            fill='#5B5B5B'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M114.14 272.18C114.14 270.99 113.08 270.23 111.14 270.23C109.2 270.23 108.14 270.99 108.14 272.18C108.14 273.37 109.14 274.13 111.14 274.13C113.14 274.13 114.14 273.37 114.14 272.18ZM113.28 272.14C113.28 272.74 112.77 273.14 111.14 273.14C109.52 273.14 108.95 272.73 108.95 272.14C108.95 271.55 109.51 271.14 111.14 271.14C112.77 271.14 113.28 271.54 113.28 272.14Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M108.85 274.85C110.39 276.45 111.4 277.45 112.28 277.45C112.545 277.484 112.812 277.397 113.006 277.212C113.199 277.028 113.3 276.767 113.28 276.5C113.237 276.052 113.004 275.644 112.64 275.38L113.22 274.8C113.779 275.243 114.107 275.917 114.11 276.63C114.151 277.115 113.98 277.595 113.64 277.944C113.301 278.293 112.826 278.478 112.34 278.45C111.34 278.45 110.28 277.5 109.08 276.36C109.08 276.66 109.14 277.04 109.14 277.36V278.77H108.25V274.89L108.85 274.85Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M114.66 281.35V282.06L106.8 279.95V279.25L114.66 281.35Z'
                            fill='#1E1E1E'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M114.14 284.57C114.14 283.38 113.08 282.62 111.14 282.62C109.2 282.62 108.14 283.38 108.14 284.57C108.14 285.76 109.14 286.52 111.14 286.52C113.14 286.52 114.14 285.76 114.14 284.57ZM113.28 284.53C113.28 285.13 112.77 285.53 111.14 285.53V285.52C109.52 285.52 108.95 285.12 108.95 284.53C108.95 283.94 109.51 283.53 111.14 283.53C112.77 283.53 113.28 283.93 113.28 284.53Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M108.85 287.23C110.39 288.82 111.4 289.83 112.28 289.83C112.545 289.864 112.812 289.777 113.006 289.592C113.199 289.408 113.3 289.147 113.28 288.88C113.237 288.432 113.004 288.024 112.64 287.76L113.22 287.18C113.779 287.623 114.107 288.297 114.11 289.01C114.151 289.495 113.98 289.975 113.64 290.324C113.301 290.673 112.826 290.858 112.34 290.83C111.34 290.83 110.28 289.88 109.08 288.74C109.08 289.04 109.14 289.42 109.14 289.74V291.15H108.25V287.27L108.85 287.23Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M114.66 293.73V294.44L106.8 292.33V291.62L114.66 293.73Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M109.09 295.3V296.62H112.87V295.52H113.52C113.593 295.989 113.752 296.44 113.99 296.85V297.62H109.09V298.74H108.24V295.29L109.09 295.3Z'
                            fill='#1E1E1E'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M108.98 301.17C108.987 300.814 109.147 300.479 109.42 300.25L108.79 299.69C108.358 300.08 108.117 300.638 108.13 301.22C108.13 302.41 109.07 303.48 111.3 303.48C113.23 303.48 114.1 302.52 114.1 301.4C114.106 300.907 113.908 300.433 113.551 300.091C113.195 299.75 112.713 299.572 112.22 299.6C111.751 299.535 111.277 299.686 110.932 300.01C110.586 300.335 110.405 300.797 110.44 301.27C110.472 301.764 110.716 302.22 111.11 302.52C109.52 302.48 108.98 301.89 108.98 301.17ZM113.3 301.41C113.3 301.97 112.86 302.39 111.86 302.5C111.49 302.283 111.25 301.898 111.22 301.47C111.22 300.89 111.53 300.55 112.22 300.55C112.91 300.55 113.3 300.85 113.3 301.41Z'
                            fill='#1E1E1E'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M111.19 305.31C111.478 304.834 111.984 304.534 112.54 304.51C112.981 304.519 113.397 304.712 113.688 305.043C113.979 305.374 114.118 305.812 114.07 306.25C114.116 306.688 113.971 307.125 113.673 307.448C113.374 307.772 112.95 307.951 112.51 307.94C111.976 307.898 111.49 307.614 111.19 307.17C110.934 307.768 110.35 308.159 109.7 308.17C108.81 308.17 108.13 307.4 108.13 306.23C108.056 305.751 108.186 305.262 108.488 304.882C108.79 304.503 109.236 304.266 109.72 304.23C110.377 304.277 110.949 304.697 111.19 305.31ZM108.88 306.24C108.851 306.482 108.928 306.725 109.091 306.907C109.253 307.089 109.486 307.192 109.73 307.19C110.332 307.19 110.56 306.633 110.858 305.903L110.9 305.8C110.663 305.43 110.26 305.198 109.82 305.18C109.555 305.195 109.306 305.316 109.13 305.515C108.954 305.714 108.864 305.975 108.88 306.24ZM113.11 306.852C112.942 307.014 112.713 307.097 112.48 307.08C112.098 307.064 111.739 306.89 111.49 306.6C111.74 305.96 111.99 305.44 112.57 305.44C112.783 305.428 112.991 305.508 113.141 305.659C113.292 305.809 113.372 306.017 113.36 306.23C113.369 306.463 113.278 306.69 113.11 306.852Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M109.6 309.23C109.222 309.575 108.999 310.058 108.98 310.57C108.941 310.876 109.045 311.182 109.262 311.401C109.478 311.62 109.784 311.726 110.09 311.69C110.378 311.711 110.661 311.606 110.866 311.403C111.071 311.2 111.178 310.918 111.16 310.63C111.159 310.296 111.047 309.972 110.84 309.71L111.15 309.21L113.99 309.38V312.45H113.11V310.29L111.72 310.17C111.846 310.404 111.911 310.665 111.91 310.93C111.95 311.418 111.773 311.9 111.426 312.246C111.08 312.593 110.598 312.77 110.11 312.73C109.575 312.747 109.056 312.542 108.677 312.163C108.298 311.784 108.093 311.265 108.11 310.73C108.079 309.98 108.37 309.252 108.91 308.73L109.6 309.23Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M84.7 165.28H83.32V165.1C84.15 164.83 84.56 164.36 84.56 163.69C84.5799 163.373 84.4677 163.061 84.25 162.83C84.0868 162.645 83.8563 162.533 83.61 162.52C83.4065 162.524 83.2157 162.62 83.09 162.78C82.9084 163.06 82.7953 163.378 82.76 163.71C82.7064 164.053 82.6124 164.389 82.48 164.71C82.3545 164.959 82.1602 165.168 81.92 165.31C81.6445 165.472 81.3296 165.555 81.01 165.55C80.5255 165.563 80.0564 165.379 79.71 165.04C79.3664 164.712 79.1775 164.255 79.19 163.78C79.2005 163.33 79.3626 162.897 79.65 162.55L79.31 162.18V162.04H80.8V162.21C80.3962 162.324 80.0325 162.55 79.75 162.86C79.5199 163.115 79.3918 163.446 79.39 163.79C79.3917 164.122 79.5083 164.444 79.72 164.7C79.9129 164.964 80.2229 165.117 80.55 165.11C80.8171 165.119 81.0753 165.013 81.26 164.82C81.4822 164.557 81.6183 164.233 81.65 163.89C81.7012 163.548 81.7883 163.213 81.91 162.89C82.0151 162.648 82.189 162.443 82.41 162.3C82.6434 162.134 82.9236 162.046 83.21 162.05C83.611 162.054 83.9961 162.207 84.29 162.48C84.6071 162.78 84.7785 163.204 84.76 163.64C84.7593 164.056 84.6223 164.46 84.37 164.79C84.469 164.89 84.5798 164.977 84.7 165.05V165.28Z'
                            fill='#0049B1'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M81.11 167.23V169.23C81.5093 169.243 81.8964 169.091 82.18 168.81C82.4235 168.562 82.5569 168.227 82.55 167.88C82.5529 167.473 82.3819 167.083 82.08 166.81C81.7664 166.5 81.3407 166.331 80.9 166.34C80.454 166.328 80.023 166.502 79.71 166.82C79.4003 167.105 79.2259 167.509 79.23 167.93C79.2303 168.225 79.3328 168.512 79.52 168.74C79.7395 169.007 80.0488 169.185 80.39 169.24V169.07C79.8222 168.987 79.3986 168.504 79.39 167.93C79.3669 167.702 79.4755 167.481 79.67 167.36C80.1367 167.222 80.6261 167.178 81.11 167.23ZM81.29 168.39V167.23L81.79 167.2C81.9434 167.191 82.093 167.25 82.2 167.36C82.3085 167.484 82.3657 167.645 82.36 167.81C82.36 168.2 82.14 168.39 81.72 168.39H81.29Z'
                            fill='#0049B1'
                        />
                        <path
                            d='M81.13 172.07L79.52 173.43V173.81H79.3V171.95H79.51V172.47L80.57 171.58L79.51 170.58V171.21H79.3V169.74H79.51V170.34L80.7 171.43L82.22 170.17V169.72H82.43V171.65H82.22V171.12L81.22 171.92L82.22 172.8V172.19H82.43V173.62H82.22V173.03L81.13 172.07Z'
                            fill='#0049B1'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M82.39 174.76C82.2949 174.671 82.1701 174.621 82.04 174.62C81.9065 174.619 81.7776 174.669 81.68 174.76C81.5829 174.85 81.5284 174.977 81.53 175.11C81.528 175.246 81.5823 175.376 81.68 175.47C81.7776 175.561 81.9065 175.611 82.04 175.61C82.1701 175.609 82.2949 175.559 82.39 175.47C82.485 175.375 82.5358 175.244 82.53 175.11C82.5355 174.979 82.4845 174.851 82.39 174.76ZM80.07 174.76C79.9749 174.671 79.8501 174.621 79.72 174.62C79.5865 174.619 79.4576 174.669 79.36 174.76C79.2629 174.85 79.2084 174.977 79.21 175.11C79.208 175.246 79.2623 175.376 79.36 175.47C79.4576 175.561 79.5865 175.611 79.72 175.61C79.8505 175.611 79.9761 175.561 80.07 175.47C80.1677 175.376 80.222 175.246 80.22 175.11C80.2216 174.977 80.1671 174.85 80.07 174.76Z'
                            fill='#0049B1'
                        />
                        <path
                            d='M84.63 178.86V179.86L82.01 180.86L81.01 181.21V181.21L82.01 181.55L84.63 182.49V183.49H79.3V182.61H81.74C82.23 182.61 82.94 182.68 83.43 182.73V182.73L82.22 182.3L79.8 181.38V180.94L82.22 180.02L83.43 179.6V179.6C82.94 179.65 82.23 179.73 81.74 179.73H79.3V178.86L84.63 178.86Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M84.44 193.24H82.15V193.72C82.1489 193.868 82.1657 194.016 82.2 194.16C82.2541 194.29 82.3488 194.399 82.47 194.47C82.678 194.584 82.913 194.639 83.15 194.63V194.84H80.9V194.62C81.2025 194.64 81.5026 194.555 81.75 194.38C81.8951 194.188 81.9661 193.95 81.95 193.71V193.23H79.52V194.32C79.4696 194.777 79.6067 195.236 79.9 195.59C80.2424 195.86 80.6638 196.011 81.1 196.02V196.22L79.31 196.17V191.45H79.52V192.29H84.44V191.45H84.64V195.97L83.22 196.07V195.86C83.5683 195.828 83.8958 195.68 84.15 195.44C84.3703 195.129 84.473 194.75 84.44 194.37V193.24Z'
                            fill='#0049B1'
                        />
                        <path
                            d='M82.23 198.18L80.06 199.29L82.23 200.29V199.62H82.44V201.02H82.23V200.49L78.61 198.89C78.2273 198.736 77.8619 198.541 77.52 198.31C77.3264 198.176 77.2106 197.956 77.21 197.72C77.2004 197.594 77.2526 197.471 77.35 197.39C77.4312 197.313 77.5384 197.27 77.65 197.27C77.7466 197.267 77.8403 197.303 77.91 197.37C77.9748 197.433 78.0109 197.52 78.01 197.61C78.0138 197.739 77.944 197.859 77.83 197.92C77.71 197.987 77.65 198.047 77.65 198.1C77.65 198.17 77.72 198.24 77.85 198.33C78.0966 198.467 78.3502 198.59 78.61 198.7L79.19 198.96L82.19 197.39V196.92H82.4V198.83H82.19L82.23 198.18Z'
                            fill='#0049B1'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M81.11 202.08V204.08C81.5093 204.093 81.8964 203.941 82.18 203.66C82.4235 203.412 82.5569 203.077 82.55 202.73C82.5516 202.323 82.3809 201.934 82.08 201.66C81.7654 201.353 81.3395 201.187 80.9 201.2C80.4552 201.184 80.024 201.355 79.71 201.67C79.4003 201.955 79.2259 202.359 79.23 202.78C79.2303 203.075 79.3328 203.362 79.52 203.59C79.7395 203.857 80.0488 204.035 80.39 204.09V203.92C79.8222 203.837 79.3986 203.354 79.39 202.78C79.3669 202.552 79.4755 202.331 79.67 202.21C80.1367 202.072 80.6261 202.028 81.11 202.08ZM81.29 203.24V202.08L81.79 202.05C81.9426 202.046 82.0903 202.104 82.2 202.21C82.3085 202.334 82.3657 202.495 82.36 202.66C82.36 203.05 82.14 203.24 81.72 203.24H81.29Z'
                            fill='#0049B1'
                        />
                        <path
                            d='M82.47 206.83H81.58V206.67C82.0328 206.623 82.3729 206.235 82.36 205.78C82.3665 205.595 82.3061 205.414 82.19 205.27C82.1129 205.144 81.9777 205.065 81.83 205.06C81.7167 205.057 81.6102 205.114 81.55 205.21C81.4493 205.423 81.3916 205.654 81.38 205.89C81.28 206.68 80.92 207.08 80.3 207.08C80.012 207.088 79.733 206.979 79.5274 206.777C79.3218 206.575 79.2072 206.298 79.21 206.01C79.2235 205.685 79.3551 205.375 79.58 205.14L79.35 204.86V204.72H80.52V204.86C79.77 205.09 79.39 205.47 79.39 206.01C79.3912 206.211 79.4618 206.405 79.59 206.56C79.6893 206.713 79.8579 206.806 80.04 206.81C80.1686 206.821 80.2937 206.764 80.37 206.66C80.495 206.411 80.5665 206.138 80.58 205.86C80.69 205.12 81.02 204.74 81.58 204.74C81.8428 204.734 82.0948 204.844 82.27 205.04C82.4508 205.243 82.5474 205.508 82.54 205.78C82.5528 206.064 82.4437 206.341 82.24 206.54C82.313 206.606 82.3935 206.663 82.48 206.71L82.47 206.83Z'
                            fill='#0049B1'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M82.39 208.15C82.2983 208.055 82.1721 208.001 82.04 208C81.9044 207.998 81.7741 208.052 81.68 208.15C81.5834 208.236 81.5287 208.36 81.53 208.49C81.53 208.772 81.7583 209 82.04 209C82.1726 208.997 82.2988 208.942 82.3907 208.847C82.4826 208.751 82.5327 208.623 82.53 208.49C82.5352 208.362 82.4841 208.237 82.39 208.15ZM80.07 208.15C79.9783 208.055 79.8521 208.001 79.72 208C79.5844 207.998 79.4541 208.052 79.36 208.15C79.2634 208.236 79.2087 208.36 79.21 208.49C79.21 208.772 79.4383 209 79.72 209C79.8526 209.002 79.9797 208.947 80.07 208.85C80.1677 208.756 80.222 208.626 80.22 208.49C80.2213 208.36 80.1667 208.236 80.07 208.15Z'
                            fill='#0049B1'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M84.63 212.27V213.97C84.63 215.07 84.3 215.87 83.32 215.87C82.7692 215.878 82.2887 215.498 82.17 214.96C82.1622 215.285 82.0247 215.594 81.788 215.818C81.5514 216.041 81.2353 216.161 80.91 216.15C79.84 216.15 79.34 215.28 79.34 214.15V212.31L84.63 212.27ZM82.43 213.89C82.43 214.63 82.71 214.95 83.21 214.95C83.71 214.95 83.9 214.6 83.9 213.9V213.21H82.43V213.89ZM80.94 215.23C80.34 215.23 80.05 214.79 80.05 214.01V213.21H81.75V214.01C81.75 214.81 81.54 215.23 80.94 215.23Z'
                            fill='#1E1E1E'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M81.39 218.88L79.3 220.03V221.09L81.52 219.82C81.7232 220.484 82.3577 220.919 83.05 220.87C84.23 220.87 84.62 220.03 84.62 218.92V217.12H79.3V218.07H81.39V218.88ZM82.13 218.78V218.03H83.87V218.78C83.87 219.51 83.66 219.91 83.05 219.91C82.44 219.91 82.13 219.51 82.13 218.78Z'
                            fill='#1E1E1E'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M84.73 224.06C84.73 222.72 83.71 221.72 82 221.72C80.3 221.72 79.22 222.71 79.22 224.06C79.22 225.41 80.27 226.41 82 226.41C83.73 226.41 84.73 225.4 84.73 224.06ZM83.91 224.06C83.91 224.9 83.19 225.44 82 225.44C80.8 225.44 80 224.9 80 224.06C80 223.22 80.81 222.69 82 222.69C83.19 222.69 83.91 223.22 83.91 224.06Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M79.52 232.29H84.44V231.45H84.64V234.08H84.44V233.24H82.16V235.53H84.44V234.7H84.64V237.32H84.44V236.48H79.52V237.32H79.3V234.7H79.51V235.53H81.95V233.24H79.51V234.08H79.3V231.45H79.51L79.52 232.29Z'
                            fill='#0049B1'
                        />
                        <path
                            d='M83.3 239.37H82.4V240.31H82.19V239.37H80.19C79.992 239.362 79.7939 239.379 79.6 239.42C79.49 239.42 79.44 239.57 79.44 239.73C79.4413 239.863 79.4832 239.992 79.56 240.1C79.6619 240.23 79.8018 240.324 79.96 240.37V240.54C79.4544 240.425 79.1202 239.944 79.19 239.43C79.1709 239.23 79.2202 239.029 79.33 238.86C79.4146 238.743 79.5391 238.661 79.68 238.63C79.8664 238.616 80.0536 238.616 80.24 238.63H82.15V237.93H82.36V238.63H83.3C83.2822 238.876 83.2822 239.124 83.3 239.37Z'
                            fill='#0049B1'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M82.39 241.49C82.2983 241.395 82.1721 241.341 82.04 241.34C81.9044 241.338 81.7741 241.392 81.68 241.49C81.5829 241.58 81.5284 241.707 81.53 241.84C81.5257 241.973 81.5807 242.101 81.68 242.19C81.7741 242.288 81.9044 242.342 82.04 242.34C82.1721 242.339 82.2983 242.285 82.39 242.19C82.4893 242.101 82.5443 241.973 82.54 241.84C82.5416 241.707 82.4871 241.58 82.39 241.49ZM80.07 241.49C79.9783 241.395 79.8521 241.341 79.72 241.34C79.5844 241.338 79.4541 241.392 79.36 241.49C79.2655 241.581 79.2145 241.709 79.22 241.84C79.2132 241.972 79.2644 242.099 79.36 242.19C79.4541 242.288 79.5844 242.342 79.72 242.34C79.8526 242.342 79.9797 242.287 80.07 242.19C80.1693 242.101 80.2243 241.973 80.22 241.84C80.2216 241.707 80.1671 241.58 80.07 241.49Z'
                            fill='#0049B1'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M83.82 247.39C83.8129 247.706 83.671 248.005 83.43 248.21L84 248.68C84.3791 248.322 84.5897 247.821 84.58 247.3C84.58 246.3 83.75 245.3 81.75 245.3C80.01 245.3 79.22 246.17 79.22 247.17C79.2138 247.617 79.3932 248.046 79.7156 248.355C80.0379 248.664 80.4741 248.825 80.92 248.8C81.3429 248.855 81.7681 248.717 82.0783 248.425C82.3884 248.132 82.5506 247.715 82.52 247.29C82.4907 246.845 82.2677 246.436 81.91 246.17C83.34 246.21 83.82 246.74 83.82 247.39ZM81.84 247.12C81.84 247.63 81.53 247.93 80.92 247.93C80.3 247.93 79.93 247.73 79.93 247.19C79.93 246.65 80.32 246.29 81.24 246.19C81.5845 246.378 81.8103 246.728 81.84 247.12Z'
                            fill='#1E1E1E'
                        />
                        <path
                            d='M81.71 249.45V251.39H81.02V249.45H81.71Z'
                            fill='#1E1E1E'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M84.58 253.81C84.58 252.74 83.67 252.05 81.93 252.05C80.18 252.05 79.22 252.74 79.22 253.81C79.22 254.88 80.19 255.57 81.93 255.57C83.67 255.57 84.58 254.88 84.58 253.81ZM83.85 253.81C83.85 254.32 83.39 254.67 81.93 254.67C80.46 254.67 79.93 254.3 79.93 253.81C79.93 253.32 80.47 252.95 81.93 252.95C83.39 252.95 83.85 253.3 83.85 253.81Z'
                            fill='#1E1E1E'
                        />
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M84.58 257.98C84.58 256.91 83.67 256.23 81.93 256.23C80.18 256.23 79.22 256.9 79.22 257.98C79.22 259.06 80.19 259.74 81.93 259.74C83.67 259.74 84.58 259.05 84.58 257.98ZM83.85 257.98C83.85 258.49 83.39 258.85 81.93 258.85C80.46 258.85 79.93 258.47 79.93 257.98C79.93 257.49 80.47 257.12 81.93 257.12C83.39 257.12 83.85 257.47 83.85 257.98Z'
                            fill='#1E1E1E'
                        />
                        <g clipPath='url(#clip1_6:2966)'>
                            <path
                                d='M225.47 322.93V71.04C225.47 63.7388 219.551 57.82 212.25 57.82H191.67V336.15H212.25C219.551 336.15 225.47 330.231 225.47 322.93Z'
                                fill='#2867B2'
                            />
                            <text
                                transform={
                                    'translate(219 ' +
                                    this.props.padding +
                                    ') rotate(90)'
                                }
                                fill='white'
                                fontFamily='Open Sans'
                                fontSize='16'
                                fontWeight='800'
                                letterSpacing='0px'
                            >
                                <tspan x='0.117188' y='17.207'>
                                    {this.props.title}
                                </tspan>
                            </text>
                        </g>
                        <rect
                            x='194.08'
                            y='57.82'
                            width='278.32'
                            height='3'
                            transform='rotate(90 194.08 57.82)'
                            fill='url(#paint2_linear_6:2966)'
                        />
                        <text
                            transform='translate(176 272) rotate(90)'
                            fill='#2867B2'
                            fontFamily='Open Sans'
                            fontSize='13'
                            fontWeight='800'
                            letterSpacing='0px'
                        >
                            <tspan x='0.360352' y='14.0432'>
                                CLASS C
                            </tspan>
                        </text>
                        <text
                            transform='translate(176 162) rotate(90)'
                            fill='#2867B2'
                            fontFamily='Open Sans'
                            fontSize='13'
                            fontWeight='800'
                            letterSpacing='0px'
                        >
                            <tspan x='0.461914' y='14.0432'>
                                12345-789
                            </tspan>
                        </text>
                    </g>
                    <defs>
                        <linearGradient
                            id='paint0_linear_6:2966'
                            x1='74.3336'
                            y1='97.2755'
                            x2='164.959'
                            y2='380.459'
                            gradientUnits='userSpaceOnUse'
                        >
                            <stop stopColor='#93CCFF' />
                            <stop offset='0.01' stopColor='#96CDFF' />
                            <stop offset='0.09' stopColor='#B5DFFF' />
                            <stop offset='0.18' stopColor='#CEEDFF' />
                            <stop offset='0.27' stopColor='#DFF7FF' />
                            <stop offset='0.36' stopColor='#EAFDFF' />
                            <stop offset='0.48' stopColor='#EDFFFF' />
                            <stop offset='0.55' stopColor='#E4FBFF' />
                            <stop offset='0.67' stopColor='#CDF0FF' />
                            <stop offset='0.81' stopColor='#A6DEFF' />
                            <stop offset='0.95' stopColor='#7CCAFF' />
                        </linearGradient>
                        <linearGradient
                            id='paint1_linear_6:2966'
                            x1='157.275'
                            y1='5.125'
                            x2='-1.69704'
                            y2='122.255'
                            gradientUnits='userSpaceOnUse'
                        >
                            <stop stopColor='#6E7181' />
                            <stop offset='0.03' stopColor='#7A7E8C' />
                            <stop offset='0.13' stopColor='#989DA7' />
                            <stop offset='0.22' stopColor='#AEB4BA' />
                            <stop offset='0.31' stopColor='#BBC1C6' />
                            <stop offset='0.39' stopColor='#BFC6CA' />
                            <stop offset='1' stopColor='#6F7282' />
                        </linearGradient>
                        <linearGradient
                            id='paint2_linear_6:2966'
                            x1='194.08'
                            y1='60.82'
                            x2='472.4'
                            y2='60.82'
                            gradientUnits='userSpaceOnUse'
                        >
                            <stop stopColor='#78E4C6' />
                            <stop offset='0.2' stopColor='#7987AB' />
                            <stop offset='0.25' stopColor='#818DB0' />
                            <stop offset='0.31' stopColor='#959CBD' />
                            <stop offset='0.38' stopColor='#B3B3CF' />
                            <stop offset='0.47' stopColor='#CCD6FC' />
                            <stop offset='0.56' stopColor='#95D5F3' />
                            <stop offset='0.7' stopColor='#9AC8E3' />
                            <stop offset='0.87' stopColor='#AEA3BE' />
                            <stop offset='0.93' stopColor='#9EA2C3' />
                            <stop offset='1' stopColor='#92A1C6' />
                        </linearGradient>
                        <clipPath id='clip0_6:2966'>
                            <rect
                                width='280'
                                height='174'
                                fill='white'
                                transform='translate(226 57) rotate(90)'
                            />
                        </clipPath>
                        <clipPath id='clip1_6:2966'>
                            <rect
                                width='280'
                                height='35'
                                fill='white'
                                transform='translate(226 57) rotate(90)'
                            />
                        </clipPath>
                    </defs>
                </svg>
            </div>
        );
    }
}

export default DriversLicenseFrontSVG;
