/**
 * @prettier
 */

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/es/integration/react';
import { connect, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

// Actions
import {
    acuantInitialized,
    setInitializationParams,
    bypassAcuant,
} from './screens/actions/configActions.js';
import {
    awaitResponse,
    responseReceived,
} from './screens/actions/spinnerActions.js';

// Services
import ApiService from './services/api/api';
import logService from './services/shared/logService.js';
import DataDogService from './services/shared/datadogService.js';
import {
    areCookiesEnabled,
    updateUrlLanguageParam,
} from './services/shared/helpers.js';
import selectStorage from './services/shared/selectStorage.js';

// Components
import CapturePhoto from './screens/CapturePhoto';
import AppEntry from './screens/AppEntry';
import StartScreen from './screens/StartScreen';
import DocumentSelection from './screens/DocumentSelection';
import EVerification from './screens/EVerification';
import CaptureSelfie from './screens/CaptureSelfie';
import Success from './screens/Success';
import MobileHandoff from './screens/MobileHandoff';
import Instructions from './screens/Instructions';
import Error from './screens/Error/index';
import ProcessedImageResult from './screens/ProcessedImageResult';
import AcuantReactCamera from './screens/AcuantReactCamera';
import ErrorBoundary from './screens/Error/ErrorBoundary.js';
import IDPalCamera from './screens/IDPalCamera.js';
import IDPalCaptureSelfie from './screens/IDPalCaptureSelfie.js';
import UploadProofOfAddress from './screens/UploadProofOfAddress.js';
import ReusableProfileStart from './screens/ReusableProfileStart.js';

// Styles
import './styles/main.css';

// Config
import { ACTION_LABELS } from './config/dataDogActionLabels';
const ACTION = ACTION_LABELS.app;

/*
global Raven
 */

function storeUserId(queryParams) {
    /*
        Replaced the logic localstorage with the selectStorage(areCookiesEnabled()), because the localstorage values are changed 
        in all tabs for the browser, the selectStorage(areCookiesEnabled()) value changed only the current tab of the browser
    */
    selectStorage(areCookiesEnabled()).removeItem('sessionid'); // always generate a new session onload
    selectStorage(areCookiesEnabled()).removeItem('submissionid'); // always get new submission onload
    selectStorage(areCookiesEnabled()).removeItem('target');
    selectStorage(areCookiesEnabled());
    decodeURI(queryParams)
        .replace('?', '')
        .split('&')
        .map(param => param.split('='))
        .reduce((values, [key, value]) => {
            if (key !== undefined || value !== undefined) {
                selectStorage(areCookiesEnabled()).removeItem(key);
                selectStorage(areCookiesEnabled()).setItem(key, value);
            }
            return null;
        }, {});
}

class App extends Component {
    constructor(props) {
        // checks that essential cookies are enabled before storing url paramaters
        // parse the data from the iframe query string and save to local storage for all
        // api requests. please note that when on the success page you click finish and the session is
        // deleted and then regenerated again as the app is forced to reload
        // device_type header also set here
        // /Applications/MAMP/htdocs/Apps/id-pal-kyc-app/frontend/src/screens/actions/configActions.js

        super(props);
        this.state = {
            isAcuantSdkLoaded: false,
        };
        this.isInitialized = false;
        this.isIntializing = false;
    }

    componentDidMount() {
        storeUserId(window.location.search);

        this.setLanguage();

        if (
            process.env.REACT_APP_SENTRY_SUBSCRIPTION_ID &&
            process.env.REACT_APP_SENTRY_SUBSCRIPTION_ID.length > 0
        ) {
            Raven.config(
                process.env.REACT_APP_SENTRY_SUBSCRIPTION_ID
            ).install();
        }

        // if (isMobile == true) {
        //     this.props.routerHistory.replace('/error/mobileonly');
        //     // document.body.classList.add('mobile-only');
        // } else {
        if (!this.props.config) {
            this.props.routerHistory.replace('/');
        }
        // }

        // if (process.env.REACT_APP_MOBILE_ONLY === 'true') {
        //     if (!isMobile) {
        //         this.props.routerHistory.replace('/error/mobileonly');
        //         document.body.classList.add('mobile-only');
        //     } else {
        //         if (!this.props.config) {
        //             this.props.routerHistory.replace('/');
        //         }
        //     }
        // } else {
        // if (!this.props.config) {
        //     this.props.routerHistory.replace('/');
        // }
        // }

        // TODO: Add a method that sets up and initialises our "idpal" db and creates a "captures" object

        // Main SDK
        const sdk = document.createElement('script');
        sdk.src = '/acuant/AcuantJavascriptWebSdk.min.js?v=20221129';
        sdk.async = true;
        sdk.onload = () => window.loadAcuantSdk();
        document.body.appendChild(sdk);

        // Acuant Camera SDK
        const acuantCameraSdk = document.createElement('script');
        acuantCameraSdk.src = '/acuant/AcuantCamera.min.js?v=20230809';
        acuantCameraSdk.async = true;
        document.body.appendChild(acuantCameraSdk);

        // Open CV
        const opencv = document.createElement('script');
        opencv.src = '/acuant/opencv.min.js';
        opencv.async = true;
        document.body.appendChild(opencv);
    }

    componentDidCatch(error, errorInfo) {
        if (
            process.env.REACT_APP_SENTRY_SUBSCRIPTION_ID &&
            process.env.REACT_APP_SENTRY_SUBSCRIPTION_ID.length > 0
        ) {
            Raven.captureException(error, { extra: errorInfo });
        }
        this.props.routerHistory.push('/error/default');
    }

    initialize() {
        if (!this.isInitialized && !this.isIntializing) {
            this.isIntializing = true;

            this.props.awaitResponse();

            ApiService.getToken()
                .then(response => {
                    if (response.hasOwnProperty('data')) {
                        AcuantJavascriptWebSdk.initializeWithToken(
                            response.data.token,
                            process.env.REACT_APP_ACAS_ENDPOINT,
                            {
                                onSuccess: function () {
                                    AcuantJavascriptWebSdk.startWorkers(() => {
                                        this.props.responseReceived();
                                        this.props.acuantInitialized(true);
                                        this.isInitialized = true;
                                        this.isIntializing = false;
                                        this.setState({
                                            isAcuantSdkLoaded: true,
                                        });
                                    });
                                }.bind(this),

                                onFail: function () {
                                    DataDogService.createError(
                                        'Could not initialise with token received.'
                                    );
                                    this.props.responseReceived();
                                    this.props.acuantInitialized(false);
                                    this.isIntializing = false;
                                    this.setState({
                                        isAcuantSdkLoaded: false,
                                    });
                                }.bind(this),
                            }
                        );
                    } else {
                        DataDogService.createError(
                            'Could not retrieve token, continuing.'
                        );
                        this.props.responseReceived();
                        this.isIntializing = false;
                        this.props.acuantInitialized(false);
                        this.props.bypassAcuant(true);
                    }
                })
                .catch(err => {
                    DataDogService.createError(
                        'Could not retrieve token, network error'
                    );
                    logService.error(err);
                    this.props.responseReceived();
                    this.isIntializing = false;
                });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Initialize once we have a valid Session
        if (prevProps.instanceID !== this.props.instanceID) {
            if (this.props.instanceID) {
                this.initialize();
            }
        }
    }

    getBrandTheme() {
        let brandTheme = 'id-pal';
        brandTheme =
            selectStorage(areCookiesEnabled()).getItem('brand') !== null &&
            selectStorage(areCookiesEnabled()).getItem('brand');
        return brandTheme;
    }

    setLanguage() {
        // Switch to US English if in US Region.
        const initialLang = selectStorage(areCookiesEnabled()).getItem('lang');
        if (
            process.env.REACT_APP_US_ENGLISH === 'true' &&
            initialLang === 'en'
        ) {
            updateUrlLanguageParam('en-US');
        }

        this.props.setInitializationParams(window.location.search);

        // Checks that essential cookies are enabled before setting the new language
        const lang = selectStorage(areCookiesEnabled()).getItem('lang');
        lang ? i18next.changeLanguage(lang) : i18next.changeLanguage('en');
    }

    render() {
        // const lang = window.appData.lang;
        // const uuid = window.appData.uuid;
        // if(!uuid || !lang) {
        //     alert(`Problem loading page, no uuid/lang. Please load from within an iframe`)
        // }
        const { t } = this.props;

        return (
            <div
                className={'mainContent brand-' + this.getBrandTheme()}
                data-dd-action-name={ACTION.name}
            >
                {
                    <Provider store={this.props.store}>
                        <PersistGate
                            loading={null}
                            persistor={this.props.persistor}
                        >
                            <ConnectedRouter history={this.props.routerHistory}>
                                <ErrorBoundary>
                                    <Switch>
                                        <Route
                                            path='/'
                                            exact
                                            component={AppEntry}
                                        />
                                        <Route
                                            path='/start'
                                            exact
                                            component={StartScreen}
                                        />
                                        <Route
                                            path='/re_verification'
                                            exact
                                            component={ReusableProfileStart}
                                        />
                                        <Route
                                            path='/select'
                                            exact
                                            component={DocumentSelection}
                                        />
                                        <Route
                                            path='/instruction/:type/:side'
                                            exact
                                            component={Instructions}
                                        />
                                        <Route
                                            path='/capture/photo/:uuid?/:device_type?'
                                            exact
                                            component={CapturePhoto}
                                        />
                                        <Route
                                            path='/capture/camera/'
                                            exact
                                            component={AcuantReactCamera}
                                        />
                                        <Route
                                            path='/capture/idpal_camera/'
                                            exact
                                            component={IDPalCamera}
                                        />
                                        <Route
                                            path='/photo/confirm/:uuid?/:device_type?'
                                            exact
                                            component={ProcessedImageResult}
                                        />
                                        <Route
                                            path='/capture/selfie/:uuid?/:device_type?'
                                            exact
                                            component={CaptureSelfie}
                                        />
                                        <Route
                                            path='/capture/idpal_selfie/:uuid?/:device_type?'
                                            exact
                                            component={IDPalCaptureSelfie}
                                        />
                                        <Route
                                            path='/success/:uuid?/:device_type?'
                                            component={Success}
                                        />
                                        <Route
                                            path='/e_verification'
                                            exact
                                            component={EVerification}
                                        />
                                        <Route
                                            path='/mobile_handoff/'
                                            exact
                                            component={MobileHandoff}
                                        />
                                        <Route
                                            path='/capture/proof_of_address/'
                                            exact
                                            component={UploadProofOfAddress}
                                        />
                                        <Route
                                            path='/error'
                                            component={Error}
                                        />
                                    </Switch>
                                </ErrorBoundary>
                            </ConnectedRouter>
                        </PersistGate>
                    </Provider>
                }
                <div className='toast' id='reconnecting'>
                    {t('idpal_reconnecting')}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            acuantInitialized,
            bypassAcuant,
            awaitResponse,
            responseReceived,
            setInitializationParams,
        },
        dispatch
    );
}

export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(App)
);
