/**
 * @prettier
 */

// React Packages
import { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { setInstanceID } from './actions/configActions';

const CustomButton = ({branding = null, isDisabled = false, ...props}) => {
    const [colorState, setColorState] = useState(null);
    const [isHovered, setIsHovered] = useState(false);

    const handleButtonClick = () => {
        props.handleClick();
        // Drop hover state after button pressed
        setTimeout(() => {
            setIsHovered(false);
        }, 500);

    };
    useEffect(() => {
        if (branding) {
            setColorState(
                branding.data.company_branding.primary_color
                    ? branding.data.company_branding.primary_color
                    : colorState
            );
        }
    }, [branding, colorState]);

    const buttonStyles = useMemo(() => {
        let styles = {
            color: '#ffffff',
            backgroundColor: `#${colorState}`,
            boxShadow: `inset 0px 0px 0px 2px #${colorState}`
        };
        if(isHovered){
            styles = {
                ...styles,
                color: `#${colorState}`,
                backgroundColor: '#ffffff',
                transition: 'all 300ms ease-in-out',

            };
        }
        if(isDisabled){
            styles = {
                ...styles,
                backgroundColor: '#d3d3d3',
                boxShadow: 'none',
            };
        }
        return styles;
    }, [colorState, isHovered, isDisabled]);

    return (
        <button
            type={props.type}
            id={props.id}
            style={buttonStyles}
            className={props.className}
            onClick={handleButtonClick}
            onMouseEnter={()=> setIsHovered(true)}
            onMouseLeave={()=> setIsHovered(false)}
            disabled={props.isDisabled}
            data-dd-action-name={props.actionDataLabel}
        >
            {props.label}
        </button>
    );
}
function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        branding: state.config.profile,
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setInstanceID }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomButton);
