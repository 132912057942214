/**
 * @prettier
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

let fadeInterval = null;
let changeMessageInterval = null;

class LoadingMessages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fadeAnimationClass: 'fade-in',
            currentMessage: 0,
            nextMessage: 1,
            animationComplete: false,
            fadeInCount: 0,
            changeMessageBreak: 200, // time in milliseconds for break between fades
            showAnimation: true,
        };
        this.addFadeOutClass = this.addFadeOutClass.bind(this);
        this.changeMessage = this.changeMessage.bind(this);
    }

    // Start the interval timers for the animation
    componentDidMount() {
        // Only show animation if submission state has not been updated
        this.checkSubmissionState();

        // prevent component remount in parent component after state update api call
        if (this.state.showAnimation) {
            this.setChangeMessageInterval();
            this.setFadeInterval();
        }
    }

    // Clear all interval timers
    componentWillUnmount() {
        this.clearFadeInterval();
        this.clearChangeMessageInterval();
    }

    // Needed prevent component remount in parent component after state update api call
    checkSubmissionState() {
        if (this.props.documentUploaded.completed) {
            this.setState({
                showAnimation: false,
            });
        }
    }

    // Adds fadeout
    addFadeOutClass() {
        // If we have reached the last message in the list, clear the fade timer.
        if (
            this.state.fadeInCount >=
            this.props.messageConfig.messages_to_animate.length - 1
        ) {
            this.clearFadeInterval();
            return;
        }

        this.setState({
            fadeAnimationClass: 'fade-out',
        });
    }

    // Increments to the next message in the list
    changeMessage() {
        // Clears message timer when the list ends
        if (this.state.animationComplete) {
            this.clearChangeMessageInterval();
            return;
        }

        // Sets animation complete for the next iteration
        if (
            this.state.nextMessage >=
            this.props.messageConfig.messages_to_animate.length
        ) {
            this.setState({
                animationComplete: true,
            });
            return;
        }

        // Increment message
        this.setState({
            currentMessage: this.state.nextMessage,
            nextMessage: this.state.nextMessage + 1,
        });

        this.setState({
            fadeAnimationClass: 'fade-in',
            fadeInCount: this.state.fadeInCount + 1,
        });
    }

    setFadeInterval() {
        if (!fadeInterval) {
            fadeInterval = setInterval(
                this.addFadeOutClass,
                this.props.messageConfig.fade_interval_ms
            );
        }
    }

    setChangeMessageInterval() {
        if (!changeMessageInterval) {
            changeMessageInterval = setInterval(
                this.changeMessage,
                this.props.messageConfig.fade_interval_ms +
                    this.state.changeMessageBreak
            );
        }
    }

    clearFadeInterval() {
        clearInterval(fadeInterval);
        fadeInterval = null;
    }

    clearChangeMessageInterval() {
        clearInterval(changeMessageInterval);
        changeMessageInterval = null;
    }

    render() {
        const { t } = this.props;

        return (
            <div className='loading-messages'>
                <div className={this.state.fadeAnimationClass}>
                    <div className='message-container'>
                        {this.state.showAnimation && (
                            <div className='message'>
                                {t(
                                    this.props.messageConfig
                                        .messages_to_animate[
                                        this.state.currentMessage
                                    ]
                                )}
                            </div>
                        )}

                        {!this.state.showAnimation && (
                            <div className='message'>
                                {t(
                                    this.props.messageConfig
                                        .messages_to_animate[
                                        this.props.messageConfig
                                            .messages_to_animate.length - 1
                                    ]
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        documentUploaded: state.submissionState.submissionState.document_upload,
    };
}

export default withTranslation('translation')(
    connect(mapStateToProps)(LoadingMessages)
);
