/**
 * @prettier
 */

export function configReducer(state = {}, action) {
    switch (action.type) {
        case '@@acuant/SET_INSTANCE_ID':
            return {
                ...state,
                instanceID: action.data,
            };
        case '@@acuant/FRONT_ID_SUBMITTED':
            return {
                ...state,
                frontSubmitted: true,
            };
        case '@@acuant/BACK_ID_SUBMITTED':
            return {
                ...state,
                backSubmitted: true,
            };
        case '@@acuant/RESET_CONFIG':
            return {
                ...state,
                instanceID: null,
                frontSubmitted: false,
                backSubmitted: false,
            };
        case '@@acuant/SET_BRANDING':
            return {
                ...state,
                profile: action,
            };
        case 'SET_UUID_VALIDITY':
            return {
                ...state,
                uuidValid: action.data,
            };
        case 'IS_RUUID':
            return {
                ...state,
                isRuuid: action.data,
            };
        case 'IS_INIT_WITH_RUUID':
            return {
                ...state,
                isInitWithRuuid: action.data,
            };
        case 'IS_RECAPTCHA_VERIFIED':
            return {
                ...state,
                isReCaptchaVerified: action,
            };
        case 'BRANDING_ERROR':
            return {
                ...state,
                isBranding: false,
            };
        case 'UUID_FROM_RUUID_ERROR':
            return {
                ...state,
                isUuidFromRuuidError: true,
            };
        case 'PRE_LOAD_BRANDING':
            return {
                ...state,
                profile: {
                    data: {
                        company_branding: action.data,
                        policies: action.data.policies,
                    },
                },
            };
        case 'LIMIT_REACHED':
            return {
                ...state,
                limitReached: true,
            };
        case 'SHOW_HEADER':
            return {
                ...state,
                showHeader: action.data,
            };
        case 'ACUANT_INITIALIZED':
            return {
                ...state,
                acuantInitialized: action.data,
            };
        case 'BYPASS_ACUANT':
            return {
                ...state,
                bypassAcuant: action.data,
            };
        case 'SET_CROP_SETTINGS':
            return {
                ...state,
                cropSettings: action.data[action.device],
            };
        case 'AUTO_LIVENESS_SUPPORTED':
            return {
                ...state,
                autoLivenessSupported: action.data,
            };
        case 'AUTO_ID_CAPTURE_SUPPORTED':
            return {
                ...state,
                autoIdCaptureSupported: action.data,
            };
        case 'IDPAL_OVERLAY_SUPPORTED':
            return {
                ...state,
                idpalOverlaySupported: action.data,
            };
        case 'SET_INITIALIZATION_PARAMS':
            return {
                ...state,
                initializationParams: action.data,
            };
        case 'IS_DOC_SUBMITTED':
            return {
                ...state,
                isDocSubmitted: action.data,
            };
        case 'IS_POA_SUBMITTED':
            return {
                ...state,
                isPoaDocSubmitted: action.data,
            };
        default:
            return state;
    }
}
