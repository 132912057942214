/**
 * @prettier
 */

import React from 'react';

// Components
import Error from './Error';
import Header from '../Header';

// Services
import logService from '../../services/shared/logService';
import DataDogService from '../../services/shared/datadogService';
import selectStorage from '../../services/shared/selectStorage';
import { areCookiesEnabled } from '../../services/shared/helpers';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            maxResetLimit: 3,
        };
        this.reset = this.reset.bind(this);
    }

    // Update state so the next render will show the fallback UI.
    static getDerivedStateFromError(error) {
        return {
            hasError: true,
        };
    }

    componentDidUpdate(prevState) {
        if (this.state.hasError !== prevState.hasError) {
            if (this.state.hasError) {
                this.setResetAttempts();
            }
        }
    }

    componentDidCatch(error) {
        logService.error(error);
        DataDogService.error('Found uncaught error');
    }

    componentWillUnmount() {
        this.setState({ hasError: false });
    }

    setResetAttempts() {
        // Checks if reset limit is reached
        const limitReached =
            selectStorage(areCookiesEnabled()).getItem('limitReached');
        if (limitReached === 'true') {
            return;
        }

        // Check if the app has reset before
        const resetAttempts =
            selectStorage(areCookiesEnabled()).getItem('resetAttempts');

        // Sets first reset attempt
        if (!resetAttempts) {
            selectStorage(areCookiesEnabled()).setItem('resetAttempts', 1);
            return;
        }

        // Increments resetAttempts if limit not reached
        if (resetAttempts) {
            let resetNum = parseInt(resetAttempts);
            resetNum++;
            if (resetNum === this.state.maxResetLimit) {
                selectStorage(areCookiesEnabled()).setItem(
                    'limitReached',
                    'true'
                );
                return;
            }

            // Set next increment
            selectStorage(areCookiesEnabled()).setItem(
                'resetAttempts',
                resetNum
            );
        }
    }

    isLimitReached() {
        const limitReached =
            selectStorage(areCookiesEnabled()).getItem('limitReached');
        // if sessionStorage is unavailable do not reset application
        return limitReached === 'true' || !areCookiesEnabled();
    }

    // Refresh app after crash.
    reset() {
        DataDogService.log('Reloading with Error Boundary Fallback UI');
        window.location.reload();
    }

    render() {
        // Renders the error component when app component tree crashes.
        if (this.state.hasError) {
            return (
                <>
                    <Header />
                    <Error
                        retryAction={() => this.reset()}
                        isLimitReached={this.isLimitReached()}
                    />
                </>
            );
        }
        // Renders the app if no crash in component tree.
        return this.props.children;
    }
}

export default ErrorBoundary;
