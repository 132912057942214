/**
 * @prettier
 */

import { environment } from '../../config/environment';

let logService = {
    /**
     * Logging data only in debug mode to prevent logging in production.
     *
     * @param message
     * @param object
     */
    log: (message, object = {}) => {
        if (environment.debugMode) {
            console.log(message, object);
        }
    },

    /**
     * Logging error data only in debug mode to prevent logging in production.
     *
     * @param message
     * @param object
     */
    error: (message, object = {}) => {
        if (environment.debugMode) {
            console.error(message, object);
        }
    },
};

export default logService;
