/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import Header from './Header';
import { withTranslation } from 'react-i18next';

// Images
import loadingGif from '../assets/img/loading.gif';
import loadingIdcardGif from '../assets/gifs/loading_idcard.gif';
import { imageAlt } from '../config/accessabilityRules';

// Components
import LoadingMessages from './Messages/LoadingMessages';

// Configs
import LOADING_MESSAGE_CONFIG from '../config/loadingMessageConfig';

class Processing extends Component {
    constructor(props) {
        super(props);
        this.primaryFocusRef = React.createRef();
        this.state = {
            imageUrl: null,
            imageAlt: imageAlt.loading,
        };
    }

    componentDidMount() {
        // Sets the document title
        const { t } = this.props;
        document.title = t('idpal_doc_title_processing');

        if (this.props.document.properties.documentId === 1) {
            this.setState({
                imageUrl: loadingGif,
                imageAlt: imageAlt.loading,
            });
        } else {
            this.setState({
                imageUrl: loadingIdcardGif,
                imageAlt: imageAlt.idCardLoading,
            });
        }

        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }
    }

    // Check if loading during classification or verification call
    setMessagesToAnimate() {
        let messageConfig = LOADING_MESSAGE_CONFIG.classify;

        if (this.props.callType === 'verify') {
            messageConfig = LOADING_MESSAGE_CONFIG.verify;
        }
        return messageConfig;
    }

    render() {
        const { t } = this.props;

        return (
            <Fragment>
                <Header />

                <div className='o-site-wrap'>
                    <div className='u-generic-text  u-text-center u-btm-buffer'>
                        <h1
                            ref={this.primaryFocusRef}
                            tabIndex={0}
                            className='loading-ellipse'
                        >
                            {t('idpal_analysing')}
                            <span className='dot1'>.</span>
                            <span className='dot2'>.</span>
                            <span className='dot3'>.</span>
                        </h1>
                    </div>

                    <div className='u-display-analysing u-text-center image-reflow-wrapper'>
                        <img
                            alt={this.state.imageAlt}
                            src={this.state.imageUrl}
                            className='capture'
                        />
                    </div>
                    {this.props.showMessages && (
                        <LoadingMessages
                            messageConfig={this.setMessagesToAnimate()}
                        />
                    )}
                </div>
            </Fragment>
        );
    }
}

export default withTranslation('translation')(Processing);
